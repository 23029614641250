<template>
  <div>
    <v-row>
      <v-col md="10" offset-md="1">
        <v-data-table
          height="55vh"
          :headers="headers"
          :items="cases"
          class="elevation-1"
          no-data-text="No cases available"
          :items-per-page.sync="options.itemsPerPage"
          :page.sync="options.page"
          :sort-by.sync="options.sortBy"
          :sort-desc.sync="options.sortDesc"
          @update:items-per-page="persistTableOptions()"
          @update:page="persistTableOptions()"
          @update:sort-by="persistTableOptions()"
          @update:sort-desc="persistTableOptions()"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Cases by Clients</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search by Client"
                single-line
                hide-details
                clearable
                @change="persistTableSearch"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                small
                outlined
                class="mb-2"
                @click="$refs.archiveFile.click()"
              >Restore Case</v-btn>
              <input v-show="false" type="file" ref="archiveFile" @change="executeRestoreCase" />
            </v-toolbar>
          </template>
          <template v-slot:body.prepend="{ }">
            <tr v-show="loading" v-for="i of 1,2,3,4">
              <td v-for="i of 1,2,3,4,5,6">
                <v-skeleton-loader type="table-cell"></v-skeleton-loader>
              </td>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr v-show="!loading" :class="[item._key === caseLastViewed ? 'last-viewed' : '']">
              <td>
                <router-link
                  v-if="item.status !== 'closed'"
                  :to="`cases/${item._key}`"
                >{{ item._key }}</router-link>
                <span v-else>{{ item._key }}</span>
              </td>
              <td>{{ clientName(item.clientKey) }}</td>
              <td>{{ displayTime(item.lastActivity) }}</td>
              <td>{{ displayTime(item.timestamp) }}</td>
              <td>
                <v-badge
                  v-if="item.newMessage"
                  icon="email"
                  color="primary"
                  offset-x="10"
                  offset-y="5"
                >
                  <v-badge
                    inline
                    left
                    :color="badgeColor(item.status)"
                    tile
                    :content="displayStatus(item.status)"
                    transition="slide-x-transition"
                  ></v-badge>
                </v-badge>
                <v-badge
                  v-else
                  inline
                  left
                  :color="badgeColor(item.status)"
                  tile
                  :content="displayStatus(item.status)"
                  transition="slide-x-transition"
                ></v-badge>
              </td>
              <td>
                <v-menu bottom left offset-x>
                  <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-icon size="18" v-on="{ ...tooltip, ...menu }">menu</v-icon>
                      </template>
                      <span>Item Actions</span>
                    </v-tooltip>
                  </template>
                  <v-list dense class="pa-0">
                    <v-list-item
                      v-for="action in userActions"
                      class="tile"
                      :key="action.name"
                      @click="action.click(item)"
                    >{{ action.name }}</v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:body.append="{ }">
            <tr v-show="loadingEntry === -1">
              <td v-for="i of 1,2,3,4,5,6">
                <v-skeleton-loader type="table-cell"></v-skeleton-loader>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "Setup",

  data: function () {
    return {
      search: null,
      actions: [
        {
          name: "Download Memo",
          click: this.confirmMemo,
          roles: ["caseManager"],
        },
        {
          name: "Archive Case",
          click: this.confirmArchiveCase,
          roles: ["caseManager"],
        },
        {
          name: "Delete Case",
          click: this.confirmDeleteCase,
          roles: ["caseManager"],
        },
      ],
      loadingEntry: null,
      loadingEntryTimeout: null,

      // Table options
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [ "lastActivity" ],
        sortDesc: [ "true" ],
      },

      // Last case viewed for highlighting
      caseLastViewed: null,
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions("admin", [
      "getCaseArchive",
      "getCaseMemo",
      "getCases",
      "getClients",
      "deleteCase",
      "putCaseArchive",
    ]),
    async initData() {
      this.setLoading(true);

      let requests = [];
      requests.push(this.getCases());
      requests.push(this.getClients());

      try {
        await Promise.all(requests);
        this.setLoading(false);
      } catch (error) {
      } finally {
        this.setLoading(false);
      }
    },
    confirmMemo(item) {
      let msg = `Download memo for the case ${item._key}?`;
      this.$store.commit("setConfirmText", msg);
      this.$store.commit("setConfirmAction", () => {
        this.downloadMemo(item);
      });
      this.$store.commit("setConfirmDialog", true);
    },
    async downloadMemo(item) {
      await this.setLoading(true);

      try {
        let result = await this.getCaseMemo(item._key);
        this.setLoading(false);
        this.$bus.$emit("successMessage", "Memo generated");

        let file_name = `${this.clientName(item.clientKey)}_${
          item._key
        }_Memo.zip`;
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.$bus.$emit("errorMessage", "Could not generate memo");
      } finally {
        this.setLoading(false);
      }
    },
    confirmArchiveCase(item) {
      let msg = `Archive the case ${item._key}?`;
      this.$store.commit("setConfirmText", msg);
      this.$store.commit("setConfirmAction", () => {
        this.archiveCase(item);
      });
      this.$store.commit("setConfirmDialog", true);
    },
    async archiveCase(item) {
      await this.setLoading(true);

      try {
        let result = await this.getCaseArchive(item._key);
        this.setLoading(false);
        this.$bus.$emit("successMessage", "Archiving succeeded");

        let file_name = `${this.clientName(item.clientKey)}_${
          item._key
        }_Archive.zip`;
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
        this.initData();
      } catch (error) {
        this.$bus.$emit("errorMessage", "Could not archive case");
      } finally {
        this.setLoading(false);
      }
    },
    confirmDeleteCase(item) {
      let msg = `Delete the case ${item._key}?`;
      this.$store.commit("setConfirmText", msg);
      this.$store.commit("setConfirmAction", () => {
        this.doDeleteCase(item);
      });
      this.$store.commit("setConfirmDialog", true);
    },
    async doDeleteCase(item) {
      await this.setLoading(true);

      try {
        await this.deleteCase(item._key);
        await this.setLoading(false);

        this.$bus.$emit("successMessage", "Case deleted");
      } catch (error) {
        await this.setLoading(false);
        this.$bus.$emit("errorMessage", "Could not archive case");
      }
    },
    clientName(clientKey) {
      let client = this.clientsKeyMap[clientKey];

      if (typeof client === "undefined") return clientKey;

      return client.name;
    },
    displayTime(timestamp) {
      let time = moment(timestamp);
      return time.format("DD.MM.YYYY hh:mm:ss");
    },
    displayStatus(status) {
      const statusCapitalized =
        status.charAt(0).toUpperCase() + status.slice(1);
      return statusCapitalized.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
    },
    badgeColor(status) {
      switch (status) {
        case "new":
          return "green";
        case "inProgress":
          return "primary";
        case "closed":
          return "red darken-4";
        default:
          return "green";
      }
    },
    async executeRestoreCase(event) {
      let files = event.target.files;

      if (files.length === 0) return;

      this.loadingEntryTimeout = setTimeout(
        () => (this.loadingEntry = -1),
        120
      );

      try {
        let file = files[0];
        await this.putCaseArchive(file);
      } catch (error) {
        this.$bus.$emit("errorMessage", "Case restore failed");
      } finally {
        // Reset the file value so it triggers with next upload
        this.$refs.archiveFile.value = "";
        this.loadingEntry = null;
        clearTimeout(this.loadingEntryTimeout);
      }
    },
    persistTableOptions() {
      localStorage.setItem("caseTableOptions", JSON.stringify(this.options));
    },
    persistTableSearch() {
      localStorage.setItem("caseTableSearch", JSON.stringify(this.search));
    },
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("admin", ["self", "clients", "cases"]),
    ...mapGetters("admin", ["clientsKeyMap"]),
    headers() {
      return [
        {
          text: "Case Number",
          align: "left",
          sortable: true,
          value: "_key",
        },
        {
          text: "Client Name",
          align: "left",
          sortable: true,
          value: "clientKey",
          filter: (value) => {
            if (!this.search) return true;

            let client = this.clientsKeyMap[value];

            if (typeof client === "undefined") {
              console.log("undefined client");
              return true;
            }

            let name = client.name;

            return name.toLowerCase().includes(this.search.toLowerCase());
          },
        },
        {
          text: "Last Activity",
          align: "left",
          sortable: true,
          value: "lastActivity",
        },
        {
          text: "Date Created",
          align: "left",
          sortable: true,
          value: "timestamp",
        },
        {
          text: "Status",
          align: "left",
          sortable: true,
          value: "status",
        },
        {
          text: "Actions",
          align: "left",
          sortable: false,
          value: "action",
        },
      ];
    },
    userActions() {
      const act = this.actions.filter(a => {
        let b = false;
        this.self.roles.forEach(r => {
          if(a.roles.includes(r)) {
            b = true;
          }
        });

        return b;
      });
      return act;
    },
  },
  mounted() {
    let tableOptions = localStorage.getItem("caseTableOptions");
    let tableSearch = localStorage.getItem("caseTableSearch");
    let lastCase = localStorage.getItem("caseLastViewed");

    if (tableOptions) this.options = JSON.parse(tableOptions);

    if (tableSearch) this.search = JSON.parse(tableSearch);

    if (lastCase) this.caseLastViewed = JSON.parse(lastCase);

    this.initData();
  },
};
</script>

<style scoped>
.tile {
  font-size: 0.85rem;
}

.last-viewed {
  background-color: #f0f0f0;
}
</style>