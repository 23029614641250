<template>
  <div>
    <v-row>
      <v-col md=3 offset-md=1>
        <v-card>
          <v-card-title>
            Last Active Cases
          </v-card-title>
          <v-card-text>
            something
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md=3>
        <v-card>
          <v-card-title>
            test
          </v-card-title>
        </v-card>
      </v-col>
      <v-col md=3>
        <v-card>
          <v-card-title>
            test
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'Setup',

  data: () => ({

  }),
  methods: {
    ...mapActions('admin', [
      'refreshToken',
    ]),
    initData() {
      if(this.token === null) {
        this.refreshToken();
      }
    }
  },
  computed: {
    ...mapState([
      'loading',
    ]),
    ...mapState('admin', [
      'token',
    ]),
  },
  mounted() {
    this.initData();
  }
};
</script>

<style>

</style>