<template>
  <div>
    <v-card width="100%">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selected"
          height="55vh"
          :headers="headers"
          :items="items"
          :search="search"
          item-key="emailAddress"
	  show-select
          class="elevation-1"
          fixed-header
          no-data-text="No users available"
        >
          <template v-slot:body.prepend="{ }">
            <tr v-show="loading" v-for="i of 1,2,3,4">
              <v-skeleton-loader type="table-cell@5"></v-skeleton-loader>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" small outlined class="mb-2" @click="saveAction">{{ savebutton }}</v-btn>
        <v-btn color="primary" small outlined class="mb-2" @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "UserSelector",

  data: function() {
    return {
      search: "",
      selected: []
    };
  },
  props: [
    "clientKey",
    "items",
    "headers",
    "initialState",
    "title",
    "savebutton"
  ],
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    saveAction() {
      this.$emit(
        "save",
        this.selected,
      );
    }
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("admin", ["users"])
  },
  watch: {
    initialState(n, o) {
      this.selected = this.initialState;
    }
  },
  mounted() {
    this.selected = this.initialState;
  }
};
</script>

<style scoped>
.tile {
  color: rgb(0, 105, 117) !important;
  font-size: 0.85rem;
  font-weight: 500;
}

.tile:hover {
  background: rgba(0, 105, 117, 0.1);
}

.tile:active {
  background: yellow;
}

.scrolling {
  overflow: auto !important;
  height: 100%;
  padding: 10px;
  scrollbar-color: #ef9a9a #fff;
  scrollbar-width: thin;

  -ms-overflow-style: auto;
  scrollbar-base-color: #ef9a9a;
  scrollbar-face-color: #ef9a9a;
  scrollbar-3dlight-color: #fff;
  scrollbar-highlight-color: #fff;
  scrollbar-track-color: #fff;
  scrollbar-arrow-color: #d30535;
  scrollbar-shadow-color: #fff;
  scrollbar-dark-shadow-color: #fff;
}

.scrolling::-webkit-scrollbar {
  width: 8px;
}

.scrolling::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 25px rgba(255, 255, 255, 1);
  border-radius: 10px;
}

.scrolling::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 25px rgba(239, 154, 154, 1);
}
</style>
