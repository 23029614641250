import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import admin from './admin'
import i18n from '../i18n'
import { Layout } from '@/assets/layout'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    loadingCallback: null,

    spinner: false,
    spinnerCallback: null,

    confirmDialog: false,
    confirmText: "",
    confirmAction: function () { },

    configuration: {},
    faqLibrary: {},

    client: {},
    layout: {},
    currentCase: { _key: "N/A", password: "N/A" },
    messages: [],
  },
  mutations: {
    setConfiguration(state, configuration) {
      state.configuration = configuration;
    },
    setFaqLibrary(state, faqLibrary) {
      state.faqLibrary = faqLibrary;
    },
    setConfirmDialog(state, confirm) {
      state.confirmDialog = confirm;
    },
    setConfirmText(state, text) {
      state.confirmText = text;
    },
    setConfirmAction(state, action) {
      state.confirmAction = action;
    },
    setLoadingCallback(state) {
      if(state.loadingCallback === null) {
        let handle = setTimeout(() => this.commit('setLoading', true), 120);
        state.loadingCallback = handle;
      }
    },
    clearLoadingCallback(state) {
      clearTimeout(state.loadingCallback);
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setSpinnerCallback(state, handle) {
      state.spinnerCallback = handle;
    },
    clearSpinnerCallback(state) {
      clearTimeout(state.spinnerCallback);
    },
    setSpinner(state, spinner) {
      state.spinner = spinner;
    },
    setClient(state, client) {
      state.client = client;
    },
    setLayout(state, layout) {
      state.layout = layout;
    },
    setCurrentCase(state, c) {
      state.currentCase = c;
    },
    setMessages(state, messages) {
      state.messages = messages;
    },
    addMessage(state, message) {
      state.messages.push(message);
    }
  },
  actions: {
    async setSpinner({ commit }, loading) {
      if (loading === true) {
        let t = setTimeout(() => commit('setSpinner', true), 120);
        commit('setSpinnerCallback', t);
      } else {
        commit('clearSpinnerCallback');
        commit('setLoading', false);
      }
    },
    async setLoading({ commit }, loading) {
      if (loading === true) {
        commit('setLoadingCallback');
      } else {
        commit('clearLoadingCallback');
        commit('setLoading', false);
      }
    },
    async getConfiguration({ commit }) {
      let url = `/api/configuration`;

      let result = await axios.get(url);

      let configuration = result.data.data;
      commit('setConfiguration', configuration);

      return result;
    },
    async getLocales({ commit }) {
      let url = `/api/configuration/locales`;

      let result = await axios.get(url);

      return result;
    },
    async getFaqLibrary({ commit }) {
      let url = `/api/configuration/faq_library`;

      let result = await axios.get(url);
      let library = result.data.data;

      commit('setFaqLibrary', library);

      return result;
    },
    async destroyAuthData({ commit }) {
      let url = `/api/destroy_auth`;

      let result = await axios.get(url);

      commit('setCurrentCase', {});
      commit('setMessages', []);

      return result;
    },
    async getClient({ commit }, clientIdentifier) {
      let url = `/api/clients/${clientIdentifier}`;

      let result = await axios.get(url);
      let client = result.data.data;

      if(client.languageOverrides) {
        client.languageOverrides.forEach(locale => {
          i18n.mergeLocaleMessage(locale.code, locale.messages);
        });
      }

      commit('setClient', client);

      return result;
    },
    async getLayout({ commit }, layoutKey) {
      let url = `/api/layouts/${layoutKey}`;

      let result = await axios.get(url);
      let object = result.data.data;
      let layout = Layout.fromObject(object);

      commit('setLayout', layout);

      return result;
    },
    async getCase({ commit }, { clientIdentifier, caseKey, password }) {
      let url = `/api/clients/${clientIdentifier}/cases/${caseKey}/decryption`;

      let result = await axios.post(url, JSON.stringify(password));
      let c = result.data.data;
      commit('setCurrentCase', c);

      return result;
    },
    async createCase({ commit }, { clientIdentifier, newCase }) {
      let url = `/api/clients/${clientIdentifier}/cases`;

      let result = await axios.post(url, newCase);
      newCase = result.data.data;
      commit('setCurrentCase', newCase);
      return result;
    },
    async getMessages({ commit }, { clientIdentifier, caseKey, password }) {
      let url = `/api/clients/${clientIdentifier}/cases/${caseKey}/messages`;

      let result = await axios.get(url);
      let messages = result.data.data;
      commit('setMessages', messages);
      return result;
    },
    async createCaseMessage({ commit }, { clientIdentifier, caseKey, message }) {
      let url = `/api/clients/${clientIdentifier}/cases/${caseKey}/messages`;

      let result = await axios.post(url, message);
      let newMessage = result.data.data;

      return result;
    },
    async createMessageAttachment({ commit }, { clientIdentifier, caseKey, messageKey, file }) {
      let url = `/api/clients/${clientIdentifier}/cases/${caseKey}/messages/${messageKey}/attachments`;

      // Create the attachment DTO
      let attachmentDTO = { messageKey: messageKey, fileName: file.name, fileSize: file.size };

      let result = await axios.post(url, attachmentDTO);

      // Retrevied new attachment
      let newAttachment = result.data.data;

      // Create URL for data post
      url = `/api/clients/${clientIdentifier}/cases/${caseKey}/messages/${messageKey}/attachments/${newAttachment._key}`;

      // Read the file as array buffer
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);

      // Post the file
      return axios.post(url, file, {
        headers: {
          'Content-Type': 'text/plain'
        }
      });
    },
  },
  getters: {
    availableLanguages: state => {
      if (!state.configuration.languages)
        return [];

      let languages = state.configuration.languages;

      if (state.client._key)
        languages = state.configuration.languages.filter(x => state.client.languages.includes(x.code));

      return languages.filter(x => x.active === true);
    },
    availableLanguagesMap: (state, getters) => {
      return getters.availableLanguages.reduce((map, obj) => (map[obj.code] = obj, map), {});
    },
    operatorName: state => {
      return state.configuration.operatorName;
    }
  },
  modules: {
    admin: admin,
  }
})
