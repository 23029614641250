<template>
  <div style="height: 60vh; width: 100vw">
    <v-main align="center" class="fill-height mt-0 pt-5" row wrap>
      <v-row v-if="loading" justify="center" align="center" class="fill-height">
        <v-col cols="6" offset="3">
          <v-skeleton-loader type="heading" class="mb-5"></v-skeleton-loader>
          <v-skeleton-loader type="paragraph" class="mb-2"></v-skeleton-loader>
          <v-skeleton-loader type="paragraph" class="mb-2"></v-skeleton-loader>
          <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </v-col>
        <v-col cols="3"></v-col>
        <v-col cols="2" offset="3">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="2">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="2">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="3"></v-col>
        <v-col cols="4" offset="4" align="center">
          <v-skeleton-loader type="button"></v-skeleton-loader>
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
      <v-row v-if="!loading" justify="start" align="center">
        <v-col
          cols="10"
          offset="1"
          sm="8"
          offset-sm="2"
          md="8"
          offset-md="2"
          lg="6"
          offset-lg="3"
          class="pa-0"
        >
          <v-card class="pa-0" flat tile color="primary" width="100%">
            <span class="white--text">
              <v-card-title
                class="justify-center canary-title mb-4"
                v-html="$t('titleClientIntro', { client: client.name, operatorName: operatorName })"
              >></v-card-title>
            </span>
            <v-card-text
              class="white--text text-left text-default"
              v-html="$t('messageClientIntro', { client: client.name, operatorName: operatorName })"
            ></v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
      <v-row v-if="!loading">
        <v-col cols="10" offset="1" lg="8" offset-lg="2">
          <v-row justify="center" align="center">
            <v-col cols="10" sm="4" md="4" class="py-0">
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-card
                      ref="card1"
                      width="100%"
                      :height="tile_height"
                      flat
                      tile
                      :class="[$vuetify.breakpoint.xsOnly ? 'mb-1' : 'mb-0', 'pa-2']"
                    >
                      <v-card-title
                        class="justify-center primary--text text-center"
                      >{{ $t('titleAnonymousCase') }}</v-card-title>
                      <v-card-text
                        class="primary--text text-center card-text"
                      >{{ $t('messageAnonymousCase') }}</v-card-text>
                      <v-card-actions class="justify-center card-actions">
                        <v-btn
                          small
                          depressed
                          color="primary"
                          @click="newCase('anonymous')"
                        >{{ $t('labelContinue') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="10" sm="4" md="4" class="py-0">
              <v-container class="py-2 px-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-card
                      ref="card2"
                      :height="tile_height"
                      width="100%"
                      flat
                      tile
                      :class="[$vuetify.breakpoint.xsOnly ? 'mb-1' : 'mb-0', 'pa-2']"
                    >
                      <v-card-title
                        class="justify-center primary--text"
                      >{{ $t('titlePersonalCase') }}</v-card-title>
                      <v-card-text
                        class="primary--text text-center card-text"
                      >{{ $t('messagePersonalCase') }}</v-card-text>
                      <v-spacer></v-spacer>
                      <v-card-actions class="justify-center card-actions text-center">
                        <v-btn
                          small
                          depressed
                          color="primary"
                          @click="newCase('personal')"
                        >{{ $t('labelContinue') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="10" sm="4" md="4" class="py-0">
              <v-card
                ref="card3"
                :height="tile_height"
                width="100%"
                flat
                tile
                :class="[$vuetify.breakpoint.xsOnly ? 'mb-1' : 'mb-0', 'pa-2']"
              >
                <v-card-title class="justify-center primary--text">{{ $t('titleExistingCase') }}</v-card-title>
                <v-card-text class="justify-center primary--text card-text">
                  <v-text-field v-model="caseKey" :label="$t('labelCaseNumber')"></v-text-field>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="justify-center card-actions">
                  <v-btn small depressed color="primary" @click="viewCase">{{ $t('labelContinue') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
  name: "Setup",
  data: () => ({
    clientId: null,
    caseKey: null,
    message: { caseId: "", message: "" },
    showCasePassword: false,
    tile_height: null,
  }),
  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions([
      "getClient",
      "getFaqLibrary",
      "createCase",
      "createCaseMessage",
    ]),
    async initData() {
      this.setLoading(true);

      let requests = [];
      requests.push(this.getFaqLibrary());
      requests.push(this.getClient(this.clientId));

      try {
        await Promise.all(requests);
      } catch (error) {
        this.$router.push({ path: "/" });
        this.$bus.$emit("errorMessage", "An unknown error occurred");
      } finally {
        this.setLoading(false);
      }
    },
    viewCase() {
      let path = `${this.$route.path}/${this.currentCase._key}`;
      this.$router.push({ path: path.replace("//", "/") });
    },
    newCase(type) {
      this.$router.push({ name: "newcase", params: { caseType: type } });
    },
    // Take the max height of the two tiles
    update_tile_height() {
      if (
        typeof this.$refs.card1 !== "undefined" &&
        typeof this.$refs.card2 !== "undefined" &&
        typeof this.$refs.card3 !== "undefined"
      ) {
        // Reset to 'null' first so the tiles automatically size
        this.tile_height = null;
        let addition = 30;

        if (this.$vuetify.breakpoint.smAndDown) addition += 15;

        if (this.$vuetify.breakpoint.mdOnly) addition += 15;

        if (this.$vuetify.breakpoint.lgAndUp) addition += 15;

        // In the next tick (we need to wait for rendering first), update tile_height with the max of the two tiles
        this.$nextTick(
          () =>
            (this.tile_height =
              Math.max(
                Math.max(
                  this.$refs.card1.$el.offsetHeight,
                  this.$refs.card2.$el.offsetHeight
                ),
                this.$refs.card3.$el.offsetHeight
              ) + addition)
        );
      } else {
        setTimeout(this.update_tile_height, 120);
      }
    },
    viewCase() {
      this.$router.push({
        name: "case",
        params: { clientId: this.clientId, caseKey: this.caseKey },
      });
    },
    registerResizeHandler() {
      window.addEventListener("resize", this.update_tile_height());
      alert("registered");
    },
    removeResizeHandler() {
      window.removeEventListener("resize", this.update_tile_height());
      alert("removed");
    },
  },
  computed: {
    ...mapGetters(["operatorName"]),
    ...mapState(["client", "currentCase", "faqLibrary", "loading"]),
    caseLink() {
      let location = window.location.toString();

      if (location.endsWith("/")) location = location.slice(0, -1);

      return `${location}/${this.currentCase._key}`;
    },
    // To avoid deep watcher
    language() {
      return this.$i18n.locale;
    },
    currentLanguageHasFaqs() {
      if (!this.faqLibrary || !this.faqLibrary.entries) return false;

      let inLanguage = this.faqLibrary.entries.filter((x) =>
        x.title.hasOwnProperty(this.$i18n.locale)
      );
      return inLanguage.length > 0;
    },
  },
  watch: {
    // Watch the language, change means text-change, update the tile_height
    language: function (v) {
      this.update_tile_height();
    },
  },
  mounted() {
    this.clientId = this.$route.params.clientId;
    this.initData();
    this.update_tile_height();
  },
};
</script>

<style>
.text-default {
  font-size: 1rem !important;
}

.card-actions {
  position: absolute;
  bottom: 10px;
  left: 0px;
  right: 0px;
}

.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}

.theme--light.v-label {
  color: #006975 !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(0, 105, 117, 0.42) !important;
}
</style>