class Field {
	static getFieldTypes() {
		let fields = [
			{
				name: 'Text Field',
				type: 'text',
			},
			{
				name: 'Long Text Field',
				type: 'longText',
			},
			{
				name: 'Number',
				type: 'number',
			},
			{
				name: 'Yes / No',
				type: 'yesNo',
			},
			{
				name: 'Date',
				type: 'date',
			},
			{
				name: 'Single Choice',
				type: 'singleChoice',
			},
			{
				name: 'Multiple Choice',
				type: 'multipleChoice',
			},
		];

		return fields;
	}

	static getTypeName(type) {
		let fields = Field.getFieldTypes();
		let field = fields.find(x => x.type === type);

		if(typeof(field) === 'undefined')
			return "N/A";
		else
			return field.name;
	}

	static capitalize = (text) => {
			text = text.toLowerCase();
			text = text.charAt(0).toUpperCase() + text.slice(1);

			return text;
	}

	static createFieldName(name) {
		if(typeof(name) === 'undefined')
			return;

		name = name.trim();
		
		let parts = name.split(' ');
		parts[0] = parts[0].toLowerCase();

		for(var i = 1; i < parts.length; i++)
		{
			parts[i] = Field.capitalize(parts[i]);
		}

		name = parts.join('');
		return name;
	}

	static fromJSON(json) {
		let ob = JSON.parse(json);

		if(ob === null || typeof(ob) === 'undefined')
			return null;

		let field = Object.assign(new Field(), ob);
		field.initialize();

		return field;
	}

	constructor(name = "empty", type = "text", locale, width = 6, required = false) {
		this.name = Field.createFieldName(name);
		this.type = type;
		this.displayNames = { [locale] : name };
		this.hints = { };
		this.required = required;
		this.fullWidth = false;
		this.width = width;
		this.outlined = false;
		this.choices = [];
		this.choiceDisplayType = 0;
		this.rows = 0;

		this.initialize();
	}

	initialize() {
		switch(this.type) {
			case "text":
				this.component = "v-text-field";
				break;
			case "longText":
				this.component = "v-textarea";
				this.fullWidth = true;
				this.outlined = true;

				if(this.rows === 0)
					this.rows = 3;
				
				break;
			case "number":
				this.component = "v-text-field";
				this.componentType = "number";
				break;
			case "yesNo":
				this.component = "v-switch";
				this.required = false;
				break;
			case "date":
				this.component = "v-date-picker";
				this.fullWidth = true;
				break;
			case "singleChoice":
				if(this.choiceDisplayType === 0)
					this.component="v-radio-group";
				else
					this.component="v-select";
				break;
			case "multipleChoice":
				this.component="v-item-group";
				break;
		}
	}

	toJSON() {
		return {
			name: this.name,
			type: this.type,
			displayNames: this.displayNames,
			hints: this.hints,
			required: this.required,
			fullWidth: this.fullWidth,
			width: this.width,
			outlined: this.outlined,
			choices: this.choices,
			choiceDisplayType: this.choiceDisplayType,
			rows: this.rows,
		};
	}
}

class Layout {
	static fromObject(ob) {
		if(ob === null || typeof(ob) === 'undefined')
			return null;

		let layout = Object.assign(new Layout(ob.name), ob);
		layout.fields = new Array();

		ob.fields.forEach(field => {
			let f = Object.assign(new Field(), field);
			f.initialize();
			layout.fields.push(f);
		});

		return layout;
	}

	constructor(name, _id=null, _key=null) {
		this.name = name;

		this.fields = new Array(0);
		this.columns = new Array(2);
		this.default = false;
		this.createdAt = new Date();
	}

	clone() {
		let l = Object.assign({}, this);
		l.fields = Array.from(this.fields);
		l.columns = Array.from(this.columns);

		return l;
	}
}

export {
	Layout,
	Field,
}