var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"75vh"}},[_c('v-row',[_c('v-col',{attrs:{"md":"10","offset-md":"1"}},[_c('v-card',{staticClass:"pa-3"},[_c('v-card-title',[_vm._v(" Global Configuration ")]),_c('v-card-text',[_c('p',[_vm._v("Global configuration values apply to all clients; therefore changes applied will be visible across all clients.")]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"type":"text"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"label":"Operator Name","disabled":!_vm.editMode},model:{value:(_vm.localConfiguration.operatorName),callback:function ($$v) {_vm.$set(_vm.localConfiguration, "operatorName", $$v)},expression:"localConfiguration.operatorName"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"type":"text"}}),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"items":_vm.localConfiguration.languages,"item-text":"name","label":"Default Language","disabled":!_vm.editMode,"return-object":""},model:{value:(_vm.localConfiguration.defaultLanguage),callback:function ($$v) {_vm.$set(_vm.localConfiguration, "defaultLanguage", $$v)},expression:"localConfiguration.defaultLanguage"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.localConfiguration.languages},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Languages")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"width":"unset","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","small":"","outlined":"","disabled":!_vm.editMode}},on),[_vm._v(" Add Language ")])]}}]),model:{value:(_vm.addLanguagesDialog),callback:function ($$v) {_vm.addLanguagesDialog=$$v},expression:"addLanguagesDialog"}},[_c('v-card',{staticClass:"pa-3"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add Language")])]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.unsetLanguages,"item-text":"name","label":"Select","dense":"","multiple":"","chips":"","hint":"Select languages to add","persistent-hint":"","small-chips":"","deletable-chips":"","return-object":""},model:{value:(_vm.languagesToAdd),callback:function ($$v) {_vm.languagesToAdd=$$v},expression:"languagesToAdd"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","small":"","outlined":""},on:{"click":_vm.addLanguages}},[_vm._v(" Add ")]),_c('v-btn',{attrs:{"color":"primary","small":"","outlined":""},on:{"click":_vm.cancelAddLanguages}},[_vm._v(" Cancel ")])],1)],1)],1)],1)]},proxy:true},{key:"body.prepend",fn:function(ref){return _vm._l((1,2,3,4),function(i){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]},_vm._l((1,2,3,4),function(i){return _c('td',[_c('v-skeleton-loader',{attrs:{"type":"table-cell"}})],1)}),0)})}},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_c('v-checkbox',{attrs:{"dense":"","disabled":!_vm.editMode},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","color":"red","disabled":!_vm.editMode},on:{"click":function($event){return _vm.deleteLanguage(item)}}},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Language")])])],1)])]}}])})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","color":"primary","outlined":""},on:{"click":function($event){_vm.editMode? _vm.save() : _vm.edit()}}},[_vm._v(" "+_vm._s(_vm.editMode ? 'Save' : 'Edit')+" ")]),_c('v-btn',{attrs:{"small":"","color":"primary","outlined":"","disabled":!_vm.editMode},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }