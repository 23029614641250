<template>
  <div>
    <v-row>
      <v-col md=10 offset-md=1>
        <v-data-table
          height="55vh"
          :headers="headers"
          :items="users"
          :search="search"
          class="elevation-1"
          fixed-header
          no-data-text="No users available"

          :items-per-page.sync="options.itemsPerPage"
          :page.sync="options.page"
          :sort-by.sync="options.sortBy"
          :sort-desc.sync="options.sortDesc"

          @update:items-per-page="persistTableOptions()"
          @update:page="persistTableOptions()"
          @update:sort-by="persistTableOptions()"
          @update:sort-desc="persistTableOptions()"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Users</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                clearable
                hide-details
                @change="persistTableSearch"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" width="unset" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    small
                    outlined
                    class="mb-2"
                    v-on="on"
                  >
                    New User
                  </v-btn>
                </template>
                <v-card class="pa-3">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="editForm">
                      <v-row align="center">
                        <v-col cols=5>
                          First Name:
                        </v-col>
                        <v-col cols=7>
                          <v-text-field
                            v-model="editedItem.firstName"
                            label="First Name"
                            :rules="nameRules"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols=5>
                          Last Name:
                        </v-col>
                        <v-col cols=7>
                          <v-text-field
                            v-model="editedItem.lastName"
                            label="Last Name"
                            :rules="nameRules"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols=5>
                          Email Address:
                        </v-col>
                        <v-col cols=7>
                          <v-text-field
                            v-model="editedItem.emailAddress"
                            label="Email Address"
                            :rules="emailRules"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols=5>
                          User Roles:
                        </v-col>
                        <v-col cols=7>
                          <v-select
                            v-model="editedItem.roles"
                            :items="roles"
                            multiple
                            item-text="name"
                            item-value="value"
                            label="User Roles"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      color="primary"
                      small
                      outlined
                      @click="save"
                    >
                      Save
                    </v-btn>
                    <v-btn
                      color="primary"
                      small
                      outlined
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:body.prepend = "{ }">
            <tr v-show="loading" v-for="i of 1,2,3,4">
              <td v-for="i of 1,2,3,4,5,6">
                <v-skeleton-loader type="table-cell"></v-skeleton-loader>
              </td>
            </tr>
          </template>
          <template v-slot:item="{ item, index }">
            <tr v-show="loadingEntry === index">
              <td v-for="i of 1,2,3,4,5,6">
                <v-skeleton-loader type="table-cell"></v-skeleton-loader>
              </td>
            </tr>
            <tr v-show="!loading && loadingEntry !== index">
              <td>
                <v-menu
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <a href="javascript:void(0);" v-on="on">{{ item.firstName }} {{ item.lastName }}</a>
                  </template>
                  <v-card width="unset" min-width="450px">
                    <v-list>
                      <v-list-item>
                        <v-list-item-avatar>
                          <img :src="getAvatarUrl(item)" :alt="getAvatarAlt(item)">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          {{ item.firstName }} {{ item.lastName }}
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                color="primary"
                                @click="prepareAvatarUpload(item)"
                                v-on="on"
                              >
                                insert_photo
                              </v-icon>
                            </template>
                            <span>Upload Avatar</span>
                          </v-tooltip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list class="body-2">
                      <v-list-item>
                        <v-list-item-content>
                          <v-col cols="8" class="py-0 my-0">
                            Assigned Cases
                          </v-col>
                          <v-col cols="4" class="py-0 my-0">
                            <v-chip small color="primary" outlined>
                               5
                             </v-chip>
                           </v-col>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-col cols="8" class="py-0 my-0">
                            Last Successfull Login
                          </v-col>
                          <v-col cols="4" class="py-0 my-0">
                            <v-chip small color="primary" outlined>
                               {{ displayDate(item.lastLogin) }}
                             </v-chip>
                           </v-col>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-col cols="8" class="py-0 my-0">
                            Failed Login Attempts
                          </v-col>
                          <v-col cols="4" class="py-0 my-0">
                            <v-chip small color="red" outlined>
                               {{ item.failedLogins }}
                             </v-chip>
                           </v-col>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <input
                      type="file"
                      ref="avatarInput"
                      v-show="false"
                      accept=".png"
                      @change="uploadUserAvatar"
                    ></input>
                  </v-card>
                </v-menu>
              </td>
              <td>
                {{ item.firstName }}
              </td>
              <td>
                {{ item.lastName }}
              </td>
              <td>
                {{ item.emailAddress }}
              </td>
              <td>
                {{ userRoles(item.roles) }}
              </td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      size="18"
                      class="mr-2"
                      @click="editItem(item)"
                      v-on="on"
                    >
                      edit
                    </v-icon>
                  </template>
                  <span>Edit User</span>
                </v-tooltip>
                <v-menu bottom left offset-x>
                  <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-icon
                          size="18"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          menu
                        </v-icon>
                      </template>
                      <span>Item Actions</span>
                    </v-tooltip>
                  </template>
                  <v-list
                    dense
                    class="pa-0"
                  >
                    <v-list-item
                      v-for="action in actions"
                      class="tile"
                      :key="action.name"
                      @click="action.click(item)"
                    >
                      {{ action.name }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:body.append="{  }">
            <tr v-show="loadingEntry === -1">
              <td v-for="i of 1,2,3,4,5,6">
                <v-skeleton-loader type="table-cell"></v-skeleton-loader>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'Setup',

  data: function() {
    return {
      dialog: false,
      search: "",
      avatarUserKey: null,
      avatarCacheKey: new Date(),
      editedIndex: -1,
      editedItem: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        role: 'caseManager',
        password: '',
        enabled: true,
        passwordChangeRequired: true,
        failedLogins: 0,
      },
      defaultItem: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        role: 'caseManager',
        password: '',
        enabled: true,
        passwordChangeRequired: true,
        failedLogins: 0,
      },

      roles: [ 
        { name: 'Case Manager', value: 'caseManager' },
        { name: 'Content Manager', value: 'contentManager' },
        { name: 'Admin', value: 'admin' }
      ],

      headers: [
        {
          text: 'Full Name',
          align: 'left',
          sortable: false,
          value: 'fullName'
        },
        {
          text: 'First Name',
          align: 'left',
          sortable: true,
          value: 'firstName',
        },
        {
          text: 'Last Name',
          align: 'left',
          sortable: true,
          value: 'lastName',
        },
        {
          text: 'Email Address',
          align: 'left',
          sortable: true,
          value: 'emailAddress',
        },
        {
          text: 'Role',
          align: 'left',
          sortable: true,
          value: 'role',
        },
        {
          text: 'Actions',
          align: 'left',
          sortable: false,
          value: 'action',
        },
      ],
      actions: [
        {
          name: "Delete User",
          click: this.confirmDeleteItem,
        },
        {
          name: "Reset Password",
          click: this.confirmResetPassword,
        }
      ],
      emailRules: [
          v => !!v || 'Please enter an email address!',
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email address must be valid!',
      ],
      nameRules: [
        v => !!v || 'Please enter a name!',
      ],

      loadingEntry: null,
      loadingEntryTimeout: null,

      // Table options
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [ "lastName" ],
        sortDesc: [ ],
      },
    }
  },
  methods: {
    ...mapActions([
      'setLoading',
    ]),    
    ...mapActions('admin', [
      'getUsers',
      'createUser',
      'updateUser',
      'deleteUser',
      'resetUserPassword',
      'updateUserAvatar',
    ]),
    async initData() {
      this.setLoading(true);

      try {
        await this.getUsers();
      } catch(error) {
        this.$bus.$emit('errorMessage', error.response.data.errors[0].message);
      } finally {
        this.setLoading(false);
      }
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    confirmDeleteItem(item)
    {
      let msg = `Delete the user ${item.firstName} ${item.lastName}?`;
      this.$store.commit('setConfirmText', msg);
      this.$store.commit('setConfirmAction', () => { this.deleteItem(item); });
      this.$store.commit('setConfirmDialog', true);
    },
    deleteItem(item) {
      this.setLoading(true);
      this.deleteUser(item._key).then((response) => {
        this.setLoading(false);
        this.$bus.$emit('successMessage', response.data.message);
      }).catch((error) => {
        this.setLoading(false);
        this.$bus.$emit('errorMessage', "Could not delete user");
      })
    },
    confirmResetPassword(item)
    {
      let msg = `Reset password for user ${item.firstName} ${item.lastName}?`;
      this.$store.commit('setConfirmText', msg);
      this.$store.commit('setConfirmAction', () => { this.resetPassword(item); });
      this.$store.commit('setConfirmDialog', true);
    },
    resetPassword(item) {
      this.setLoading(true);
      this.resetUserPassword(item._key).then((response) => {
        this.setLoading(false);
        this.$bus.$emit('successMessage', response.data.message);
      }).catch((error) => {
        this.setLoading(false);
        this.$bus.$emit('errorMessage', error.response.data.errors[0].message);
      });
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.$refs.editForm.resetValidation();
      }, 300)
    },
    async save() {
      if(this.$refs.editForm.validate() === true) {
        if (this.editedIndex > -1) {
          this.loadingEntryTimeout = setTimeout(() => this.loadingEntry = this.editedIndex, 120);

          try {
            await this.updateUser(this.editedItem);
            this.$bus.$emit('successMessage', 'User updated');
            this.close();
          } catch(error) {
            this.$bus.$emit('errorMessage', error.response.data.errors[0].message);
          } finally {
            this.loadingEntry = null;
            clearTimeout(this.loadingEntryTimeout);
          }
        } else {

          try {
            this.loadingEntryTimeout = setTimeout(() => this.loadingEntry = this.editedIndex, 120);
            await this.createUser(this.editedItem);
              this.$bus.$emit('successMessage', 'User created');
              this.close();
          } catch(error) {
            this.$bus.$emit('errorMessage', error.response.data.errors[0].message);
          } finally {
            this.loadingEntry = null;
            clearTimeout(this.loadingEntryTimeout);
          }
        }
      }
    },
    prepareAvatarUpload(user) {
      this.avatarUserKey = user._key;
      this.$refs.avatarInput.click();
    },
    async uploadUserAvatar(event) {
      let file = event.target.files[0];
      let dto = { userKey: this.avatarUserKey, file: file };

      try {
        await this.updateUserAvatar(dto);
        this.avatarCacheKey = new Date();
      } catch(error) {
        this.$bus.$emit('errorMessage', error.response.data.errors[0].message);
      } finally {

      }
    },
    userRoles(roles) {
      let roleNames = [];
      roles.forEach(r => {
        const role = this.roles.find(x => x.value === r);
        roleNames.push(role.name);
      })

      return roleNames.join(", ");
    },
    getAvatarUrl(user) {
      return `/avatars/${user._key}.png?rnd=${this.avatarCacheKey}`;
    },
    getAvatarAlt(user) {
      return `${user.firstName} ${user.lastName}`;
    },
    persistTableOptions() {
      localStorage.setItem('userTableOptions', JSON.stringify(this.options));
    },
    persistTableSearch() {
      localStorage.setItem('userTableSearch', JSON.stringify(this.search));
    },
    displayDate(timestamp) {
      let time = moment(timestamp);
      return time.format("DD.MM.YYYY");
    },
  },
  computed: {
    ...mapState([
      'loading',
    ]),
    ...mapState('admin', [
      'users',
    ]),
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  async mounted() {
    let tableOptions = localStorage.getItem('userTableOptions');
    let tableSearch = localStorage.getItem('userTableSearch');

    if(tableOptions)
      this.options = JSON.parse(tableOptions);

    if(tableSearch)
      this.search = JSON.parse(tableSearch);

    await this.initData();
  },
};
</script>

<style scoped>
.tile {
  font-size: 0.85rem;
}

.scrolling {
    overflow: auto !important;
    height: 100%;
    padding: 10px;
    scrollbar-color: #EF9A9A #fff;
    scrollbar-width: thin;

    -ms-overflow-style: auto;
    scrollbar-base-color: #EF9A9A;
    scrollbar-face-color: #EF9A9A;
    scrollbar-3dlight-color: #fff;
    scrollbar-highlight-color: #fff;
    scrollbar-track-color: #fff;
    scrollbar-arrow-color: #d30535;
    scrollbar-shadow-color: #fff;
    scrollbar-dark-shadow-color: #fff;
}

.scrolling::-webkit-scrollbar {
  width: 8px;
}

.scrolling::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 25px rgba(255,255,255,1); 
    border-radius: 10px;
}
 
.scrolling::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 25px rgba(239,154,154,1); 
}

</style>