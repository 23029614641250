<template>
<div style="height: 100vh; background-color: #fff;">
  <v-app-bar
    fixed
    color="white"
    clipped-left
    flat
   >
    <v-row>
      <div class="header pt-4 pl-4">
        <img src="/img/logo_petrol.svg" class="header"></img>
      </div>
      <v-spacer/>
      <v-btn icon large @click="close" class="mr-5">
        <v-icon large color="primary">close</v-icon>
      </v-btn>
    </v-row>
  </v-app-bar>
  <v-card tile flat style="position: relative; top: 50px;">
    <v-card-title>
      <v-col
        cols="10"
        offset="1"
        md="6"
        offset-md="3"
        xl="4"
        offset-xl="4"

      >
      Impressum
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-col
        cols="10"
        offset="1"
        md="6"
        offset-md="3"
        xl="4"
        offset-xl="4"
      >
        <p>
            <strong>Kontaktadresse</strong><br>
            ENQUIRE Rechtsanwälte AG<br>
            Beethovenstrasse 24<br>
            8002 Zürich<br>
            Schweiz<br>
            info@enquire.ch
        </p>
        <p>
            <strong>Vertretungsberechtigte Personen</strong><br>
            Claudia Fritsche<br>
            Beethovenstrasse 24<br>
            8002 Zürich
        </p>
        <p>
            <strong>Handelsregistereintrag</strong><br>
            Eingetragener Firmenname: Enquire Rechtsanwälte AG<br>
            Nummer: CHE-184.106.998
        </p>
        <p>
            <strong>Mehrwertsteuernummer</strong><br>
            CH-020.3.044.827-0
        </p>
        <p>
            <strong>Haftungsausschluss</strong><br>
            Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität,
            Zuverlässigkeit und Vollständigkeit der Informationen.<br>
            Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff
            oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder
            durch technische Störungen entstanden sind, werden ausgeschlossen.<br>
            Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das
            gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung
            zeitweise oder endgültig einzustellen.
        </p>
        <p>
            <strong>Haftung für Links</strong><br>
            Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs Es wird jegliche
            Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf
            eigene Gefahr des Nutzers oder der Nutzerin.
        </p>
        <p>
            <strong>Urheberrechte</strong><br>
            Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website
            gehören ausschliesslich&nbsp;der Firma ENQUIRE Rechtsanwälte&nbsp;AG oder den speziell genannten Rechtsinhabern. Für
            die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung der Urheberrechtsträger im Voraus
            einzuholen.
        </p>
        <p>
            <strong>Quelle</strong><br>
            Dieses Impressum wurde am&nbsp;11.07.2017 erstellt.
        </p>
      </v-col>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
export default {
  name: 'Impressum',
  data: () => ({
  }),
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

.logo {
  fill: #ffffff;
}

</style>
