<template>
  <div style="height: 75vh;">
    <v-row v-if="loading">
      <v-col md=10 offset-md=1>
          <v-skeleton-loader
            height="75px"
            type="heading"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            height="75px"
            type="table-row"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            type="card"
          >
          </v-skeleton-loader>
        </v-col>
    </v-row>
    <v-row v-else>
      <v-col md=10 offset-md=1>
        <v-card
          class="pa-3"
        >
          <v-card-title>
            Language Configuration
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols=2>
                <v-select
                    v-model="currentLocale"
                    label="Languages"
                    item-value="code"
                    item-text="name"
                    hint="Language to modify"
                    persistent-hint
                    dense
                    :items="currentLocales"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-btn
                    outlined
                    color="primary"
                    small
                    @click="exportCurrentLanguage"
                >
                  Export
                </v-btn>
                <v-btn
                    class='ml-2'
                    outlined
                    color="primary"
                    small
                    @click="triggerImport"
                >
                  Import
                </v-btn>
                <input type='file' ref='fileInput' class='hidden-sm-and-up hidden-sm-and-down' accept='.json' @change='readImportedFile'/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card-text>
        <v-divider/>
          <v-tabs
            grow
            v-model="tab"
          >

            <v-tab>
              Welcome Screen
            </v-tab>
            <v-tab>
              Client Screen
            </v-tab>
            <v-tab>
              General Case
            </v-tab>
            <v-tab>
              Anonymous Case
            </v-tab>
            <v-tab>
              Personal Case
            </v-tab>
            <v-tab>
              Existing Case View
            </v-tab>
            <v-tab>
              Buttons & Labels
            </v-tab>
            <v-tab>
              Errors
            </v-tab>
  

            <v-tab-item v-if="localesLoaded" eager>
              <v-card flat>
                <v-card-text>
                  <v-form ref="welcomeForm">
                    <v-text-field
                      v-model="localesMap[currentLocale].messages.titleWelcome"
                      label="Title - Welcome"
                      :rules="requiredRules"
                    >
                    </v-text-field>
                    <v-textarea
                      outlined
                      no-resize
                      v-model="localesMap[currentLocale].messages.messageWelcome"
                      rows="4"
                      label="Message - Welcome"
                      :rules="requiredRules"
                    >
                    </v-textarea>
                    <v-textarea
                      outlined
                      no-resize
                      v-model="localesMap[currentLocale].messages.messageWelcomeAddendum"
                      rows="4"
                      label="Message - Welcome Addendum"
                    >
                    </v-textarea>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    outlined
                    color="primary"
                    small
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>

            <v-tab-item v-if="localesLoaded" eager>
              <v-card flat>
                <v-card-text>
                  <v-form ref="clientForm">
                    <v-row>
                      <v-col cols=4>
                        <v-text-field
                          dense
                          outlined
                          label="Title - Client Intro"
                          v-model="localesMap[currentLocale].messages.titleClientIntro"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=12>
                        <v-textarea
                          outlined
                          no-resize
                          v-model="localesMap[currentLocale].messages.messageClientIntro"
                          rows="6"
                          label="Message - Client Intro"
                          :rules="requiredRules"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols=4>
                        <v-text-field
                          dense
                          outlined
                          label="Title - Anonymous Case"
                          v-model="localesMap[currentLocale].messages.titleAnonymousCase"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                        <v-textarea
                          outlined
                          no-resize
                          v-model="localesMap[currentLocale].messages.messageAnonymousCase"
                          rows="5"
                          label="Message - Anonymous Case"
                          :rules="requiredRules"
                        >
                        </v-textarea>
                      </v-col>
                      <v-col cols=4>
                        <v-text-field
                          dense
                          outlined
                          label="Title - Personal Case"
                          v-model="localesMap[currentLocale].messages.titlePersonalCase"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                        <v-textarea
                          outlined
                          no-resize
                          v-model="localesMap[currentLocale].messages.messagePersonalCase"
                          rows="5"
                          label="Message - Personal Case"
                          :rules="requiredRules"
                        >
                        </v-textarea>
                      </v-col>
                      <v-col cols=4>
                        <v-text-field
                          dense
                          outlined
                          label="Title - Existing Case"
                          v-model="localesMap[currentLocale].messages.titleExistingCase"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                        <v-text-field
                          dense
                          outlined
                          label="Label - Case Number"
                          v-model="localesMap[currentLocale].messages.labelCaseNumber"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    outlined
                    color="primary"
                    small
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>

            <v-tab-item v-if="localesLoaded" eager>
              <v-card flat>
                <v-card-text>
                  <v-form ref="generalCaseForm">
                    <v-row>
                      <v-col cols=4>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.titleNewCase"
                          label="Title - New Case"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols=4>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelAttachments"
                          label="Label - Attachments"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=4>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.hintAttachments"
                          label="Hint - Attachments"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=4>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelCreateCase"
                          label="Button - Create Case"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=4>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelLogout"
                          label="Button - Logout"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols=4>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.titleCaseSuccess"
                          label="Title - Case Created"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-textarea
                      outlined
                      no-resize
                      v-model="localesMap[currentLocale].messages.messageCaseSuccess"
                      rows="6"
                      label="Message - Case Created"
                      :rules="requiredRules"
                    >
                    </v-textarea>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    outlined
                    color="primary"
                    small
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>

            <v-tab-item v-if="localesLoaded" eager>
              <v-card flat>
                <v-card-text>
                  <v-form ref="anonymousCaseForm">
                    <v-row>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelNickName"
                          label="Label - Nick Name"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.hintNickName"
                          label="Hint - Nick Name"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols=3>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.titleAnonymousCasePopup"
                          label="Title - Anonymous Case Popup"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-textarea
                      outlined
                      no-resize
                      v-model="localesMap[currentLocale].messages.messageAnonymousCasePopup"
                      rows="6"
                      label="Message - Anonymous Case Popup"
                      :rules="requiredRules"
                    >
                    </v-textarea>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    outlined
                    color="primary"
                    small
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>

            <v-tab-item v-if="localesLoaded" eager>
              <v-card flat>
                <v-card-text>
                  <v-form ref="personalCaseForm">
                    <v-row>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelFirstName"
                          label="Label - First Name"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.hintFirstName"
                          label="Hint - First Name"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelLastName"
                          label="Label - Last Name"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.hintLastName"
                          label="Hint - Last Name"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelEmailAddress"
                          label="Label - Email Address"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.hintEmailAddress"
                          label="Hint - Email Address"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelStaffNumber"
                          label="Label - Staff Number"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.hintStaffNumber"
                          label="Hint - Staff Number"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols=3>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.titlePersonalCasePopup"
                          label="Title - Personal Case Popup"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-textarea
                      outlined
                      no-resize
                      v-model="localesMap[currentLocale].messages.messagePersonalCasePopup"
                      rows="6"
                      label="Message - Personal Case Popup"
                      :rules="requiredRules"
                    >
                    </v-textarea>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    outlined
                    color="primary"
                    small
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>

            <v-tab-item v-if="localesLoaded" eager>
              <v-card flat>
                <v-card-text>
                  <v-form ref="existingCaseForm">
                    <v-row>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.titleCasePassword"
                          label="Title - Case Password"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelPassword"
                          label="Label - Case Password"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.hintPassword"
                          label="Hint - Case Password"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.titleReplyToCase"
                          label="Title - Reply To Case"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelSubmit"
                          label="Button - Submit Message"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelViewCaseDetails"
                          label="Button - View Case Details"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.titleCaseDetails"
                          label="Title - Case Details"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelReply"
                          label="Button - Reply"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelSendMessage"
                          label="Button - Send Message"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelAnonymousCaseCreated"
                          label="Label - Anonymous Case Created"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols=2>
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelPersonalCaseCreated"
                          label="Label - Personal Case Created"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    outlined
                    color="primary"
                    small
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>

          <v-tab-item v-if="localesLoaded" eager>
              <v-card flat>
                <v-card-text>
                  <v-form ref="buttonLabelForm">
                    <v-row>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.titleConfirmationRequired"
                          label="Title - Confirmation Required"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.messageConfirmLogout"
                          label="Message - Logout Confirmation"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.messageConfirmBack"
                          label="Message - Confirm Back"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelContinue"
                          label="Button - Continue"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelBack"
                          label="Button - Back"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelClose"
                          label="Button - Close"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelConfirm"
                          label="Button - Confirm"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelCancel"
                          label="Button - Cancel"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.titleFaq"
                          label="Title - FAQ"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.titleCase"
                          label="Title - Case"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelHelp"
                          label="Label - Help"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.labelImpressum"
                          label="Label - Impressum"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>               
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    outlined
                    color="primary"
                    small
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>

          <v-tab-item v-if="localesLoaded" eager>
              <v-card flat>
                <v-card-text>
                  <v-form ref="errorLabelForm">
                    <v-row>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.errorCaseDoesNotExist"
                          label="Error - Case does not exist"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.errorWrongPassword"
                          label="Error - Wrong password"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.errorTooManyFiles"
                          label="Error - Too many files"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.errorFieldRequired"
                          label="Error - Field required"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="localesMap[currentLocale].messages.errorInvalidEmail"
                          label="Error - Invalid Email Address"
                          :rules="requiredRules"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>               
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    outlined
                    color="primary"
                    small
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
  data: () => ({
    tab: 0,
    currentLocale: 'en',
    localesLoaded: false,
    locales: [],
    requiredRules: [
      v => !!v || 'Field required',
    ],
  }),
  computed: {
    ...mapState([
      'loading',
      'configuration',
    ]),
    currentLocales() {
      if(!this.locales || this.locales.length === 0)
        return [];

      return this.locales.map(x => ({ code: x.code, name: x.name } ));
    },
    localesMap() {
      if(!this.locales || this.locales.length === 0)
        return {};

      return this.locales.reduce((map, obj) => (map[obj.code] = obj, map), {})
    },
  },
  methods: {
    ...mapMutations([
      'setLoading',
    ]),
    ...mapActions([
      'getLocales',
    ]),
    ...mapActions('admin', [
      'getIdentity',
      'updateLocale',
    ]),
    async save() {
      if (!this.$refs.welcomeForm.validate()) {
        this.tab = 0;
      } else if (!this.$refs.clientForm.validate()) {
        this.tab = 1;
      } else if (!this.$refs.generalCaseForm.validate()) {
        this.tab = 2;
      } else if (!this.$refs.anonymousCaseForm.validate()) {
        this.tab = 3;
      } else if (!this.$refs.personalCaseForm.validate()) {
        this.tab = 4;
      } else if (!this.$refs.existingCaseForm.validate()) {
        this.tab = 5;
      } else if (!this.$refs.buttonLabelForm.validate()) {
        this.tab = 6;
      } else if (!this.$refs.errorLabelForm.validate()) {
        this.tab = 7;
      } else {
        this.setLoading(true);
        try {
          let result = await this.updateLocale(this.localesMap[this.currentLocale]);
          this.$bus.$emit('successMessage', 'Language configuration stored');
        } catch(error) {
          this.$bus.$emit('errorMessage', 'Could not store language configuration');
        } finally {
          this.setLoading(false);
        }
      }
    },
    triggerImport() {
      this.$refs.fileInput.click();
    },
    readImportedFile(e) {
      const files = e.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        try {
          this.localesMap[this.currentLocale].messages = JSON.parse(fileReader.result);
          this.$refs.fileInput.value = null;
        } catch(err) {
          this.$bus.$emit('errorMessage', err.message);
        }
      })
      fileReader.readAsText(files[0])
      this.image = files[0]
    },
    exportCurrentLanguage() {
      let itemData = { ...this.localesMap[this.currentLocale].messages };

      const data = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(itemData, null, 4));
      let lnk = document.createElement('a');
      lnk.setAttribute("href", data);
      lnk.setAttribute("download", `${this.localesMap[this.currentLocale].name}_Language.json`);
      lnk.click();
      lnk.remove();
    },
  },
  async mounted() {
    this.setLoading(true);
    try {
      // As we do not fetch any attributes that require authentication, we
      // fetch the identity here. That way a 'timed out' session will be
      // forwarded to the login screen. Otherwise users might invest a lot
      // of work into editing but they could never store it.
      await this.getIdentity();

      let result = await this.getLocales();
      let locales = result.data.data;
      this.locales = locales;

      this.currentLocale = this.configuration.defaultLanguage.code;
      
      this.localesLoaded = true;
    } catch(error) {
      this.$bus.$emit('errorMessage', 'Failed loading locales');
    } finally {
      this.setLoading(false);
    }
  }
}

</script>
