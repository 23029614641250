<template>
  <div>
    <v-row>
      <v-col md="10" offset-md="1">
        <v-data-table
          height="55vh"
          :headers="headers"
          :items="clients"
          :search="search"
          class="elevation-1"
          fixed-header
          no-data-text="No clients available"
          :items-per-page.sync="options.itemsPerPage"
          :page.sync="options.page"
          :sort-by.sync="options.sortBy"
          :sort-desc.sync="options.sortDesc"
          @update:items-per-page="persistTableOptions()"
          @update:page="persistTableOptions()"
          @update:sort-by="persistTableOptions()"
          @update:sort-desc="persistTableOptions()"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Clients</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                clearable
                hide-details
                @change="persistTableSearch"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" width="unset" max-width="500px">
                <template v-slot:activator="{ on }" v-if="self.admin">
                  <v-btn color="primary" small outlined class="mb-2" v-on="on">New Client</v-btn>
                </template>
                <v-card class="pa-3">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="editForm">
                      <v-row align="center">
                        <v-col cols="5">Client Name:</v-col>
                        <v-col cols="7">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Client Name"
                            :rules="nameRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="5">Languages:</v-col>
                        <v-col cols="7">
                          <v-select
                            dense
                            label="Languages"
                            :items="availableLanguages"
                            multiple
                            item-text="name"
                            item-value="code"
                            v-model="editedItem.languages"
                            :rules="languagesRules"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="5">Layout:</v-col>
                        <v-col cols="7">
                          <v-autocomplete
                            dense
                            label="Layout"
                            :items="layouts"
                            clearable
                            item-text="name"
                            item-value="_key"
                            v-model="editedItem.layoutKey"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="5">Alternative Url:</v-col>
                        <v-col cols="7">
                          <v-text-field
                            clearable
                            v-model="editedItem.altIdentifier"
                            label="Alternative Url"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="5">Notification Emails:</v-col>
                        <v-col cols="7">
                          <v-text-field
                            v-model="editedItem.notificationAddress"
                            clearable
                            label="Notification Email Address"
                            :rules="emailRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="5">Hide Global FAQ:</v-col>
                        <v-col cols="7">
                          <v-checkbox
                              v-model="editedItem.hideGlobalFaq"
                              label="Hide Global FAQ"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="5">Disable Document Upload:</v-col>
                        <v-col cols="7">
                          <v-checkbox
                              v-model="editedItem.disableDocumentUpload"
                              label="Disable Document Upload"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn color="primary" small outlined @click="save">Save</v-btn>
                    <v-btn color="primary" small outlined @click="close">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:body.prepend="{ }">
            <tr v-show="loading" v-for="i of 1,2,3,4">
              <td v-for="i of 1,2,3,4,5,6,7">
                <v-skeleton-loader type="table-cell"></v-skeleton-loader>
              </td>
            </tr>
          </template>
          <template v-slot:item="{ item, index }">
            <tr v-show="loadingEntry === index">
              <td v-for="i of 1,2,3,4,5,6,7,8">
                <v-skeleton-loader type="table-cell"></v-skeleton-loader>
              </td>
            </tr>
            <tr v-show="!loading && loadingEntry !== index">
              <td>{{ item.name }}</td>
              <td>{{ item.identifier }}</td>
              <td>{{ item.altIdentifier }}</td>
              <td>{{ item.notificationAddress }}</td>
              <td>{{ getLanguages(item) }}</td>
              <td>{{ getDefaultLanguage(item) }}</td>
              <td>
                <v-tooltip bottom v-if="self.admin">
                  <template v-slot:activator="{ on }">
                    <v-icon small class="mr-2" @click="editItem(item)" v-on="on">edit</v-icon>
                  </template>
                  <span>Edit Client</span>
                </v-tooltip>
                <v-menu bottom left offset-x>
                  <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-icon size="18" v-on="{ ...tooltip, ...menu }">menu</v-icon>
                      </template>
                      <span>Item Actions</span>
                    </v-tooltip>
                  </template>
                  <v-list dense class="pa-0">
                    <v-list-item
                      v-for="action in userActions"
                      class="tile"
                      :key="action.name"
                      @click="action.click(item)"
                    >{{ action.name }}</v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:body.append="{ }">
            <tr v-show="loadingEntry === -1">
              <td v-for="i of 1,2,3,4,5,6,7">
                <v-skeleton-loader type="table-cell"></v-skeleton-loader>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="caseManagerDialog" max-width="60%">
      <selector
        :items="caseManagers"
        :headers="assignmentHeaders"
        :clientKey="assignmentKey"
        :initialState="assignmentState"
        title="Assign Users"
        savebutton="Save Assignment"
        @cancel="caseManagerDialog = !caseManagerDialog"
        @save="saveAssignments"
      ></selector>
    </v-dialog>
    <v-dialog v-model="faqDialog" max-width="70%">
      <faq-editor
        :faqentries="faqEntries"
        :languages="faqLanguages"
        :locale="faqLocale"
        :showcancel="true"
        @cancel="cancelFaqEdit"
        @save="saveFaq"
      ></faq-editor>
    </v-dialog>
    <input type="file" ref="languageUpload" style="display: none" @change="setLanguageOverride"/>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import moment from "moment";
import { Layout } from "@/assets/layout";
import Selector from "@/components/admin/Selector";
import FaqEditor from "@/components/admin/configuration/FaqEditor";

export default {
  name: "Setup",
  components: {
    Selector,
    FaqEditor,
  },

  data: function () {
    return {
      dialog: false,
      search: "",
      editedKey: "",
      editedIndex: -1,
      editedItem: {
        name: "",
        altIdentifier: "",
        notificationAddress: null,
        managers: [],
      },
      defaultItem: {
        name: "",
        altIdentifier: "",
        notificationAddress: null,
        managers: [],
      },
      defaultLayout: null,
      layouts: [],

      caseManagerDialog: false,
      assignmentKey: null,
      assignmentState: [],
      assignmentHeaders: [
        {
          text: "First Name",
          align: "left",
          sortable: true,
          value: "firstName",
        },
        {
          text: "Last Name",
          align: "left",
          sortable: true,
          value: "lastName",
        },
        {
          text: "Email Address",
          align: "left",
          sortable: true,
          value: "emailAddress",
        },
      ],

      faqDialog: false,
      faqLanguages: null,
      faqEntries: null,
      faqLocale: null,

      headers: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Url",
          align: "left",
          sortable: true,
          value: "identifier",
        },
        {
          text: "Alternative Url",
          align: "left",
          sortable: true,
          value: "altIdentifier",
        },
        {
          text: "Notification Address",
          align: "left",
          sortable: true,
          value: "notificationAddress",
        },
        {
          text: "Languages",
          align: "left",
          sortable: true,
          value: "languages",
        },
        {
          text: "Default Language",
          align: "left",
          sortable: true,
          value: "defaultLanguage",
        },
        {
          text: "Actions",
          align: "left",
          sortable: false,
          value: "action",
        },
      ],
      actions: [
        {
          name: "Assign Case Managers",
          click: this.showAssignCaseManagers,
          roles: ['admin'],
        },
        {
          name: "Manage Client FAQ",
          click: this.showFaqDialog,
          roles: ['contentManager'],
        },
        {
          name: "Upload Language Override",
          click: this.selectLanguageOverride,
          roles: ['contentManager'],
        },
        {
          name: "Clear Language Override",
          click: this.clearLanguageOverride,
          roles: ['contentManager'],
        },
        {
          name: "Delete Client",
          click: this.confirmDeleteItem,
          roles: ['admin'],
        },
      ],
      nameRules: [(v) => !!v || "Please enter a name!"],
      languagesRules: [(v) => !!v || "At least one language required"],
      loadingEntry: null,
      loadingEntryTimeout: null,

      // Table options
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [ "name" ],
        sortDesc: [],
      },
    };
  },
  methods: {
    ...mapActions(["getConfiguration", "setLoading"]),
    ...mapActions("admin", [
      "getClients",
      "createClient",
      "updateClient",
      "deleteClient",
      "getLayouts",
      "assignManagers",
      "getManagers",
      "getUsers",
      "updateClientLanguage",
      "updateClientFaq",
    ]),
    async initData() {
      await this.setLoading(true);

      let requests = [];

      requests.push(this.getClients());
      requests.push(this.getConfiguration());
      requests.push(this.getLayouts());

      try {
        let results = await Promise.all(requests);

        let layouts = results[2].data.data;
        layouts.forEach((layout) => {
          let l = Layout.fromObject(layout);
          this.layouts.push(l);
        });

        let defaultLayout = layouts.find((x) => x.default === true);

        if (defaultLayout) {
          this.editedItem.layoutKey = defaultLayout._key;
          this.defaultLayout = defaultLayout._key;
        }
      } catch (error) {
      } finally {
        this.setLoading(false);
      }
    },
    editItem(item) {
      this.editedIndex = this.clients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    confirmDeleteItem(item) {
      let msg = `Delete the client ${item.name}? This will delete all data of that client!`;
      this.$store.commit("setConfirmText", msg);
      this.$store.commit("setConfirmAction", () => {
        this.deleteItem(item);
      });
      this.$store.commit("setConfirmDialog", true);
    },
    deleteItem(item) {
      this.setLoading(true);
      this.deleteClient(item._key)
        .then((response) => {
          this.setLoading(false);
          this.$bus.$emit("successMessage", response.data.message);
        })
        .catch((error) => {
          this.setLoading(false);
          this.$bus.$emit("errorMessage", error.response.data.errors[0].message);
        });
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.layoutKey = this.defaultLayout;
        this.editedIndex = -1;
        this.$refs.editForm.resetValidation();
      }, 300);
    },
    async save() {
      if (this.$refs.editForm.validate() === true) {
        if (this.editedIndex > -1) {
          try {
            this.loadingEntryTimeout = setTimeout(
              () => (this.loadingEntry = this.editedIndex),
              120
            );
            await this.updateClient(this.editedItem);
            this.$bus.$emit("successMessage", "Client updated");
            this.close();
          } catch (error) {
            this.$bus.$emit("errorMessage", error.response.data.errors[0].message);
          } finally {
            clearTimeout(this.loadingEntryTimeout);
            this.loadingEntry = null;
          }
        } else {
          try {
            this.loadingEntryTimeout = setTimeout(
              () => (this.loadingEntry = this.editedIndex),
              120
            );
            await this.createClient(this.editedItem);
            this.$bus.$emit("successMessage", "Client created");
            this.close();
          } catch (error) {
            this.$bus.$emit("errorMessage", error.response.data.errors[0].message);
          } finally {
            clearTimeout(this.loadingEntryTimeout);
            this.loadingEntry = null;
          }
        }
      }
    },
    selectLanguageOverride(client) {
      this.editedKey = client._key;
      this.$refs.languageUpload.click();
    },
    setLanguageOverride(e) {
      const files = e.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        try {
          const locales = JSON.parse(fileReader.result);
          this.updateClientLanguage({ key: this.editedKey, locales: locales} );
          this.$bus.$emit("successMessage", "Language override stored");
          this.$refs.languageUpload.value = null;
        } catch(err) {
          this.$bus.$emit('errorMessage', err.message);
        }
      })
      fileReader.readAsText(files[0])
      this.image = files[0]
    },
    clearLanguageOverride(client) {
      this.editedKey = client._key;
      this.updateClientLanguage({ key: this.editedKey, locales: "null"} );
    },
    persistTableOptions() {
      localStorage.setItem("clientTableOptions", JSON.stringify(this.options));
    },
    persistTableSearch() {
      localStorage.setItem("clientTableSearch", JSON.stringify(this.search));
    },
    async showAssignCaseManagers(item) {
      this.setLoading(true);

      let requests = [];
      requests.push(this.getManagers(item._key));
      requests.push(this.getUsers());

      try {
        let results = await Promise.all(requests);
        let currentManagers = results[0].data.data;

        this.assignmentState = this.caseManagers.filter(x => currentManagers.includes(x._key));

        this.assignmentKey = item._key;
        this.caseManagerDialog = true;
      } catch (error) {
        this.$bus.$emit("errorMessage", error.response.data.errors[0].message);
      } finally {
        this.setLoading(false);
      }
    },
    async saveAssignments(managers) {
      this.setLoading(true);

      try {
        let dto = {
          clientKey: this.assignmentKey,
          managers: managers.map((x) => x._key),
        };
        await this.assignManagers(dto);
      } catch (error) {
        this.$bus.$emit("errorMessage", error.response.data.errors[0].message);
      } finally {
        this.caseManagerDialog = false;
        this.setLoading(false);
        this.assignmentKey = null;
      }
    },
    getLanguages(client) {
      return client.languages
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
        .join(", ");
    },
    getDefaultLanguage(client) {
      let lang = client.languages[0];
      return lang.charAt(0).toUpperCase() + lang.slice(1);
    },
    async showFaqDialog(client) {
      this.editedIndex = this.clients.indexOf(client);
      this.editedItem = Object.assign({}, client);

      if (!this.editedItem.faqEntries) this.editedItem.faqEntries = [];

      let languages = this.configuration.languages.filter((x) =>
        client.languages.includes(x.code)
      );
      this.faqLanguages = languages;
      this.faqEntries = this.editedItem.faqEntries;
      this.faqLocale = this.editedItem.languages[0];
      this.faqDialog = true;
    },
    async saveFaq(faqEntries) {
      this.editedItem.faqEntries = faqEntries;

      try {
        this.loadingEntryTimeout = setTimeout(
          () => (this.loadingEntry = this.editedIndex),
          120
        );
        await this.updateClientFaq(this.editedItem);
        this.$bus.$emit("successMessage", "Client FAQ stored");
        this.cancelFaqEdit();
      } catch (error) {
        this.$bus.$emit("errorMessage", error.response.data.errors[0].message);
      } finally {
        clearTimeout(this.loadingEntryTimeout);
      }
    },
    cancelFaqEdit() {
      this.faqDialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.layoutKey = this.defaultLayout;
        this.editedIndex = -1;
        this.loadingEntry = null;
        this.faqLanguages = null;
        this.faqLocale = null;
        this.faqEntries = null;
      }, 300);
    },
  },
  computed: {
    ...mapGetters(["availableLanguages"]),
    ...mapState(["loading", "configuration"]),
    ...mapGetters("admin", ["caseManagers"]),
    ...mapState("admin", ["clients", "users", "self"]),
    userActions() {
      const act = this.actions.filter(a => {
        let b = false;
        this.self.roles.forEach(r => {
          if(a.roles.includes(r)) {
            b = true;
          }
        });

        return b;
      });
      return act;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    emailRules() {
      return [
        (v) => {
          if (v === null || v.length === 0) return true;
          else
            return (
              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
              "Email address must be valid!"
            );
        },
      ];
    },
  },
  async mounted() {
    let tableOptions = localStorage.getItem("clientTableOptions");
    let tableSearch = localStorage.getItem("clientTableSearch");

    if (tableOptions) this.options = JSON.parse(tableOptions);

    if (tableSearch) this.search = JSON.parse(tableSearch);

    await this.initData();
  },
};
</script>

<style>
.tile {
  font-size: 0.85rem;
}

.scrolling {
  overflow: auto !important;
  height: 100%;
  padding: 10px;
  scrollbar-color: #ef9a9a #fff;
  scrollbar-width: thin;

  -ms-overflow-style: auto;
  scrollbar-base-color: #ef9a9a;
  scrollbar-face-color: #ef9a9a;
  scrollbar-3dlight-color: #fff;
  scrollbar-highlight-color: #fff;
  scrollbar-track-color: #fff;
  scrollbar-arrow-color: #d30535;
  scrollbar-shadow-color: #fff;
  scrollbar-dark-shadow-color: #fff;
}

.scrolling::-webkit-scrollbar {
  width: 8px;
}

.scrolling::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 25px rgba(255, 255, 255, 1);
  border-radius: 10px;
}

.scrolling::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 25px rgba(239, 154, 154, 1);
}
</style>