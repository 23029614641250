<template>
  <div style="height: 75vh;">
   <v-row v-if="loading">
      <v-col md=10 offset-md=1>
          <v-skeleton-loader
            height="75px"
            type="heading"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            height="75px"
            type="table-row"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            type="card"
          >
          </v-skeleton-loader>
        </v-col>
    </v-row>
    <v-row v-else>
      <v-col md=10 offset-md=1>
        <faq-editor
          :faqentries="faqLibrary.entries"
          :languages="this.configuration.languages"
          :locale="currentLocale"
          @save="saveFaq"
        >
        </faq-editor>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import FaqEditor from '@/components/admin/configuration/FaqEditor'

export default {
  components: {
    FaqEditor,
  },
  data: () => ({
    currentLocale: 'en',
    faqLibrary: {},
  }),
  computed: {
    ...mapState([
      'loading',
      'configuration',
    ]),
  },
  methods: {
    ...mapMutations([
      'setLoading',
    ]),
    ...mapActions([
      'getConfiguration',
      'getFaqLibrary',
    ]),
    ...mapActions('admin', [
      'getIdentity',
      'updateFaqLibrary',
    ]),
    async initData() {
      this.setLoading(true);

      try {
        let result = await this.getFaqLibrary()

        this.faqLibrary = result.data.data;
        this.currentLocale = this.configuration.defaultLanguage.code;

      } catch(error) {
        this.$bus.$emit('errorMessage', 'Failed loading configuration');
      } finally {
        this.setLoading(false);
      }
    },
    async saveFaq(faqEntries) {
      this.setLoading(true);

      try {
        this.faqLibrary.entries = faqEntries;
        await this.updateFaqLibrary(this.faqLibrary);
        await this.initData();
      } catch(error) {
        this.$bus.$emit('errorMessage', 'Could not update FAQ library');
      } finally {
        this.setLoading(false);
      }
    }
  },
  async mounted() {
    this.setLoading(true);

    let requests = [];
    requests.push(this.getConfiguration());
    requests.push(this.initData());

    try {
      // As we do not fetch any attributes that require authentication, we
      // fetch the identity here. That way a 'timed out' session will be
      // forwarded to the login screen. Otherwise users might invest a lot
      // of work into editing but they could never store it.
      await this.getIdentity();

      await Promise.all(requests);
    } catch(error) {
      this.$bus.$emit('errorMessage', 'Could not load configuration data');
    }
  }
}
</script>

<style scoped>

</style>