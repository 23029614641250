<template>
  <div>
    <v-row v-if="loading">
      <v-col md=10 offset-md=1>
        <v-skeleton-loader
          type="card"
        >
        </v-skeleton-loader>
        <v-skeleton-loader
          type="table"
        >
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md=10 offset-md=1>
        <div style="height: 70vh;">
          <div>
            <v-data-table
              height="50vh"
              :headers="headers"
              :items="layouts"
              :loading="loading"
              :search="search"
              class="elevation-1"
              fixed-header
              no-data-text="No layouts available"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Layouts</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    small
                    outlined
                    class="mb-2"
                    @click="itemDialog = true"
                  >
                    New Layout
                  </v-btn>
                  <v-btn
                      color="primary"
                      small
                      outlined
                      class="ml-2 mb-2"
                      @click="triggerImport"
                  >
                    Import Layout
                    <input type='file' ref='fileInput' class='hidden-sm-and-up hidden-sm-and-down' accept='.json' @change='readImportedFile'/>
                  </v-btn>
                  <v-dialog v-model="itemDialog" width="unset" @keydown.esc="closeItemDialog">
                    <v-card>
                      <v-card-title>
                        {{ formTitle }}
                      </v-card-title>
                      <v-card-text>
                        <v-form ref="editForm">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Layout Name"
                            :rules="newLayoutNameRules"
                          >
                          </v-text-field>
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          small
                          color="primary"
                          outlined
                          @click="saveItem"
                        >
                          Save Layout
                        </v-btn>
                        <v-btn
                          small
                          color="primary"
                          outlined
                          @click="closeItemDialog"
                        >
                          Cancel
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="layoutEditorDialog" width="100%" max-width="100%" @keydown.esc="closeLayoutEditor" persistent>
                    <layout-editor
                      v-if="layoutEditorDialog"
                      :layout="editedItem"
                      @close="closeLayoutEditor"
                    >
                    </layout-editor>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:body.prepend = "{ }">
                <tr v-show="loading" v-for="i of 1,2,3,4">
                  <td v-for="i of 1,2,3,4,5">
                    <v-skeleton-loader type="table-cell"></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-slot:item="{ item, index }">
                <tr v-show="loadingEntry === index">
                  <td v-for="i of 1,2,3,4,5">
                    <v-skeleton-loader type="table-cell"></v-skeleton-loader>
                  </td>
                </tr>
                <tr v-show="!loading && loadingEntry !== index">
                  <td>
                    <a href="javascript:void(0)" @click="openLayoutEditor(item)">{{ item.name }}</a>
                  </td>
                  <td>
                    {{ item.fields.length }}
                  </td>
                  <td>
                    <v-checkbox dense v-model=item.default @change="setDefaultLayout(item)"></v-checkbox>
                  </td>
                  <td>
                    {{ displayTime(item.createdAt) }}
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="exportLayout(item)"
                            v-on="on"
                        >
                          save
                        </v-icon>
                      </template>
                      <span>Export Layout</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="copyLayout(item)"
                          v-on="on"
                        >
                          content_copy
                        </v-icon>
                      </template>
                      <span>Clone Layout</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="copyLayout(item)"
                          v-on="on"
                        >
                          content_copy
                        </v-icon>
                      </template>
                      <span>Clone Layout</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="editItem(item)"
                          v-on="on"
                        >
                          edit
                        </v-icon>
                      </template>
                      <span>Edit Layout</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          @click="confirmDeleteItem(item)"
                          v-on="on"
                        >
                          delete
                        </v-icon>
                      </template>
                      <span>Delete Layout</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-slot:body.append="{ }">
                <tr v-show="loadingEntry === -1">
                  <td v-for="i of 1,2,3,4,5">
                    <v-skeleton-loader type="table-cell"></v-skeleton-loader>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import LayoutEditor from '@/components/admin/configuration/LayoutEditor.vue';
import { Layout } from '@/assets/layout'

export default {
  name: 'Layouts',
  components: {
    LayoutEditor,
  },

  data: () => ({
    search: "",

    copyLayoutDialog: false,
    copiedItem: {
      name: '',
      default: '',
      fields: [],
    },

    itemDialog: false,
    newLayoutName: '',
    newLayoutNameRules: [
      v => !!v || 'Please enter a layout name',
    ],

    layoutEditorDialog: false,

    editedIndex: -1,
    editedItem: {
      name: '',
      default: '',
      fields: [],
    },
    defaultItem: {
      name: '',
      default: '',
      fields: [],
    },

    headers: [
      {
        text: 'Layout Name',
        align: 'left',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Number of Fields',
        align: 'left',
        sortable: true,
        value: 'numberOfFields',
      },
      {
        text: 'Default',
        align: 'left',
        sortable: true,
        value: 'default',
      },
      {
        text: 'Date Created',
        align: 'left',
        sortable: true,
        value: 'createdAt',
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: false,
        value: 'action',
      },      
    ],

    layouts: [],
    loadingEntry: null,
    loadingEntryTimeout: null,
  }),
  methods: {
    ...mapActions([
      'setLoading',
    ]),
    ...mapActions('admin', [
      'createLayout',
      'getLayouts',
      'updateLayout',
      'deleteLayout',
    ]),
    async initData() {
      this.setLoading(true);
      this.layouts = new Array();

      try {
        let result = await this.getLayouts();
        let layouts = result.data.data;
        layouts.forEach(layout => {
          let l = Layout.fromObject(layout);
          this.layouts.push(l);
        });

      } catch(error) {
      } finally {
        this.setLoading(false);
      }
    },
    triggerImport() {
      this.$refs.fileInput.click();
    },
    async importLayout(json) {
      let layout;

      try {
        layout = JSON.parse(json);
      } catch(err) {
        this.$bus.$emit('errorMessage', err.message);
        return;
      }


      let iteration = 0;
      const nameTemplate = layout.name;
      do {
        if(iteration)
          layout.name = `${nameTemplate} - Imported_${iteration}`;

        iteration++;
      }
      while(this.layouts.findIndex(l => l.name === layout.name) !== -1)


      try {
        this.loadingEntryTimeout = setTimeout(() => this.loadingEntry = this.editedIndex, 120);
        let result = await this.createLayout(layout);
        let l = result.data.data;
        this.layouts.push(l);
      } catch(error) {
      } finally {
        clearTimeout(this.loadingEntryTimeout);
        this.loadingEntry = null;
        this.$refs.fileInput.value = null;
      }
    },
    readImportedFile(e) {
      const files = e.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', async () => {
        await this.importLayout(fileReader.result);
      })
      fileReader.readAsText(files[0])
      this.image = files[0]
    },
    exportLayout(item) {
      let itemData = { ...item };
      delete itemData._id;
      delete itemData._key;
      delete itemData._rev;
      delete itemData.createdAt;
      itemData.default = false;

      const data = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(itemData, null, 4));
      let lnk = document.createElement('a');
      lnk.setAttribute("href", data);
      lnk.setAttribute("download", `${item.name}_Layout.json`);
      lnk.click();
      lnk.remove();
    },
    copyLayout(item) {
      this.copiedItem = Object.assign({}, item);
      this.copiedItem.name += " (Copy)";
      this.copyLayoutDialog = true;
    },
    async saveCopiedLayout() {
      if(this.$refs.copyForm.validate() === true) {
        try {
          this.loadingEntryTimeout = setTimeout(() => this.loadingEntry = this.editedIndex, 120);
          let result = await this.createLayout(this.copiedItem);
          let l = result.data.data;
          this.layouts.push(l);
          this.copyLayoutDialog = false;
          setTimeout(() => {
            this.openLayoutEditor(l);  
          }, 250);
        } catch(error) {
        } finally {
          clearTimeout(this.loadingEntryTimeout);
          this.loadingEntry = null;
        }
      }
    },
    cancelCopyLayout() {
      this.copiedItem = {
        name: '',
        default: '',
        fields: [],
      };
      this.copyLayoutDialog = false;
    },
    editItem(item) {
      this.editedIndex = this.layouts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.itemDialog = true;
    },
    closeItemDialog() {
      this.itemDialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.$refs.editForm.reset();
      }, 200);
    },
    async saveItem() {
      if(this.$refs.editForm.validate() === true) {
        if (this.editedIndex > -1) {
          try {
            this.loadingEntryTimeout = setTimeout(() => this.loadingEntry = this.editedIndex, 120);
            let updated = await this.updateLayout(this.editedItem);

            let idx = this.layouts.findIndex(x => x._key === updated._key);

            if (idx !== -1) {
              Vue.set(this.layouts, idx, updated);
            }

            this.closeItemDialog();
          } catch(error) {
          } finally {
            clearTimeout(this.loadingEntryTimeout);
            this.loadingEntry = null;
          }
        } else {
          let l = new Layout(this.editedItem.name);
          try {
            this.loadingEntryTimeout = setTimeout(() => this.loadingEntry = this.editedIndex, 120);
            let result = await this.createLayout(l);
            l = result.data.data;

            this.layouts.push(l);
            this.closeItemDialog();
            setTimeout(() => {
              this.openLayoutEditor(l);  
            }, 250);
          } catch(error) {
          } finally {
            clearTimeout(this.loadingEntryTimeout);
            this.loadingEntry = null;
          }
        }
      }
    },
    confirmDeleteItem(item)
    {
      let msg = `Delete the layout ${item.name}?`;
      this.$store.commit('setConfirmText', msg);
      this.$store.commit('setConfirmAction', () => { this.deleteItem(item); });
      this.$store.commit('setConfirmDialog', true);
    },
    deleteItem(item) {
      this.deleteLayout(item._key).then((response) => {
        let idx = this.layouts.findIndex(l => l._key === item._key);
        this.layouts.splice(idx, 1);
      }).catch((error) => {

      });
    },
    displayTime(timestamp) {
      let time = moment(timestamp);
      return time.format("DD.MM.YYYY hh:mm:ss");
    },
    openLayoutEditor(item) {
      this.editedIndex = this.layouts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.layoutEditorDialog = true;
    },
    closeLayoutEditor() {
      this.layoutEditorDialog = false;
      this.initData();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    async setDefaultLayout(layout) {
      let requests = new Array();

      this.setLoading(true);

      // Set all other layouts as non default
      if(layout.default === true) {
        let nonDefault = this.layouts.filter(x => x._key !== layout._key && x.default === true);

        nonDefault.forEach(x => {
          x.default = false;
          requests.push(this.updateLayout(x));
        });
      }

      requests.push(this.updateLayout(layout));

      Promise.all(requests).then((response) => {
        this.setLoading(false);
      }).catch((error) => {
        this.setLoading(false);
      });
    }
  },
  computed: {
    ...mapState([
      'loading',
    ]),
    ...mapState('admin', [
    ]),
    formTitle () {
      return this.editedIndex === -1 ? 'New Layout' : 'Edit Layout'
    },
  },
  async mounted() {
    await this.initData();
  }
};
</script>

<style scoped>
.preserve {
  white-space: pre-line;
}

.case {
  border: 1px solid green;
}

.message-history {
    overflow: auto !important;
    height: 100%;
    padding: 10px;
    scrollbar-color: #EF9A9A #fff;
    scrollbar-width: thin;

    -ms-overflow-style: auto;
    scrollbar-base-color: #EF9A9A;
    scrollbar-face-color: #EF9A9A;
    scrollbar-3dlight-color: #fff;
    scrollbar-highlight-color: #fff;
    scrollbar-track-color: #fff;
    scrollbar-arrow-color: #d30535;
    scrollbar-shadow-color: #fff;
    scrollbar-dark-shadow-color: #fff;
}

.message-history::-webkit-scrollbar {
  width: 8px;
}

.message-history::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 25px rgba(255,255,255,1); 
    border-radius: 10px;
}
 
.message-history::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 25px rgba(239,154,154,1); 
}

.container {
  height: 95%;
}

</style>
