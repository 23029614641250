import Vue from 'vue'
import axios from 'axios'
import router from '../router'

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

if(process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'https://10.12.0.1';
}

axios.interceptors.request.use((config) => {
  const token = admin.state.token;

  if (token != null && config) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;

}, (error) => {
  reject(error);
});

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {

  // Not related to authentication, return reject
  if (error.response && error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if (!error.config) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if (error.config && error.config.url == '/api/login') {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  if (error.config && error.config.url == '/api/refresh_token') {
    admin.state.authenticated = false;

    // Clear token storage?

    if (router.currentRoute.path !== '/admin/login') {
      router.push({ path: '/admin/login' });
    }

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  return new Promise((resolve, reject) => {
    let url = `/api/refresh_token`;

    axios.get(url).then((response) => {
      let token = response.data.data.token;
      let jwt = parseJwt(token);

      admin.state.token = token;
      admin.state.authenticated = true;
      admin.state.self.role = jwt.scope;
      admin.state.self._key = jwt.sub;

      const config = error.config;

      resolve(axios.request(config));
    }).catch((error) => {
      reject(error);
    });
  });

});


const admin = {
  namespaced: true,
  state: {
    authenticated: false,
    token: null,
    self: {
      admin: false,
      contentManager: false,
      caseManager: false,
      roles: []
    },

    clients: [],
    cases: [],
    users: [],
  },
  mutations: {
    clearToken(state) {
      state.token = null;
    },
    setToken(state, token) {
      state.token = token;

      let jwt = parseJwt(token);
      state.self.roles = jwt.scope;
      state.self._key = jwt.sub;

      state.self.admin = jwt.scope.includes("admin");
      state.self.contentManager = jwt.scope.includes("contentManager");
      state.self.caseManager = jwt.scope.includes("caseManager");
    },
    setAuthenticated(state, auth) {
      state.authenticated = auth;
    },
    setClients(state, clients) {
      state.clients = clients;
    },
    addClient(state, client) {
      state.clients.push(client);
    },
    updateClient(state, client) {
      let idx = state.clients.findIndex(x => x._key === client._key);

      if (idx !== -1) {
        Vue.set(state.clients, idx, client);
      }
    },
    removeClient(state, clientKey) {
      let idx = state.clients.findIndex(x => x._key === clientKey);

      if (idx === -1)
        return;

      state.clients.splice(idx, 1);
    },
    setCases(state, cases) {
      state.cases = cases;
    },
    addCase(state, newCase) {
      state.cases.push(newCase);
    },
    removeCase(state, caseKey) {
      let idx = state.cases.findIndex(x => x._key === caseKey);

      if (idx === -1)
        return;

      state.cases.splice(idx, 1);
    },
    setUsers(state, users) {
      state.users = users;
    },
    addUser(state, user) {
      state.users.push(user);
    },
    updateUser(state, user) {
      let idx = state.users.findIndex(x => x._key === user._key);

      if (idx !== -1) {
        Vue.set(state.users, idx, user);
      }
    },
    removeUser(state, userKey) {
      let idx = state.users.findIndex(x => x._key === userKey);

      if (idx === -1)
        return;

      state.users.splice(idx, 1);
    }
  },
  actions: {
    async getIdentity({ commit }) {
      let url = `/api/identity`;

      let result = await axios.get(url);

      return result;
    },
    async logout({ commit }) {
      let url = `/api/logout`;

      let result = await axios.post(url);

      commit('clearToken');
      commit('setAuthenticated', false);

      return result;
    },
    async refreshToken({ commit }) {
      let url = `/api/refresh_token`;

      return axios.get(url);
    },
    async changePassword({ commit }, credentials) {
      let url = `/api/change_password`;

      return axios.post(url, credentials);
    },
    async sendCredentials({ commit }, credentials) {
      let url = `/api/login`;

      return axios.post(url, credentials);
    },
    async getClients({ commit }) {
      let url = `/api/admin/clients`;

      let result = await axios.get(url);
      let clients = result.data.data;

      commit('setClients', clients);
      return result;
    },
    async createClient({ commit }, client) {
      let url = `/api/admin/clients`;

      let result = await axios.post(url, client);
      let newClient = result.data.data;
      commit('addClient', newClient);

      return result;
    },
    async updateClient({ commit }, client) {
      let url = `/api/admin/clients`;
      let result = await axios.patch(url, client);
      commit('updateClient', result.data.data);

      return result;
    },
    async updateClientLanguage({ commit }, { key, locales }) {
      let url = `/api/admin/clients/${key}/language-overrides`;
      let result = await axios.patch(url, locales);

      return result;
    },
    async updateClientFaq({ commit }, client) {
      let url = `/api/admin/clients/${client._key}/faq`;
      let result = await axios.patch(url, client.faqEntries);
      commit('updateClient', client);

      return result;
    },
    async deleteClient({ commit }, client_key) {
      let url = `/api/admin/clients/${client_key}`;

      let result = await axios.delete(url);
      commit('removeClient', client_key);

      return result;
    },
    async getLayouts({ commit }) {
      let url = `/api/admin/layouts`;

      let result = await axios.get(url);

      return result;
    },
    async createLayout({ commit }, layout) {
      let url = `/api/admin/layouts`;

      let result = await axios.post(url, layout);

      return result;
    },
    async updateLayout({ commit }, layout) {
      let url = `/api/admin/layouts`;

      let result = await axios.patch(url, layout);

      return result;
    },
    async deleteLayout({ commit }, layoutKey) {
      let url = `/api/admin/layouts/${layoutKey}`;

      let result = await axios.delete(url);

      return result;
    },
    async getCases({ commit }) {
      let url = `/api/admin/cases`;

      let result = await axios.get(url);
      let cases = result.data.data;

      commit('setCases', cases);

      return result;
    },
    async getCase({ commit }, caseKey) {
      let url = `/api/admin/cases/${caseKey}`;

      return axios.get(url);
    },
    async deleteCase({ commit }, caseKey) {
      let url = `/api/admin/cases/${caseKey}`;

      let result = await axios.delete(url);

      commit('removeCase', caseKey);

      return result;
    },
    async getCaseMessages({ commit }, caseKey) {
      let url = `/api/admin/cases/${caseKey}/messages`;

      return axios.get(url);
    },
    async getAttachmentData({ commit }, { caseKey, messageKey, attachmentKey }) {
      let url = `/api/admin/cases/${caseKey}/messages/${messageKey}/attachment_data/${attachmentKey}`;

      return axios.get(url, { responseType: 'arraybuffer', });
    },
    async createCaseMessage({ commit }, { caseKey, message }) {
      let url = `/api/admin/cases/${caseKey}/messages`;

      return axios.post(url, message);
    },
    async getCaseMemo({ commit }, caseKey) {
      let url = `/api/admin/cases/${caseKey}/memo`;

      return axios.get(url, { responseType: 'arraybuffer', });
    },
    async getCaseArchive({ commit }, caseKey) {
      let url = `/api/admin/cases/${caseKey}/archive`;

      return axios.get(url, { responseType: 'arraybuffer', });
    },
    async putCaseArchive({ commit }, file) {
      // Create URL for data post
      let url = `/api/admin/cases/0/archive`;

      // Read the file as array buffer
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);

      // Putt the archive file
      let result = await axios.put(url, file, {
        headers: {
          'Content-Type': 'text/plain'
        }
      });

      let restoredCase = result.data.data;
      commit('addCase', restoredCase);

      return result;
    },
    async getUsers({ commit }) {
      let url = `/api/admin/users`;

      let result = await axios.get(url);
      let users = result.data.data;
      commit('setUsers', users);

      return result;
    },
    async createUser({ commit }, user) {
      let url = `/api/admin/users`;

      let result = await axios.post(url, user);
      let newUser = result.data.data;
      commit('addUser', newUser);

      return result;
    },
    async updateUser({ commit }, user) {
      let url = `/api/admin/users`;
      let result = await axios.patch(url, user);
      commit('updateUser', result.data.data);

      return result;
    },
    async deleteUser({ commit }, userKey) {
      let url = `/api/admin/users/${userKey}`;

      let result = await axios.delete(url);
      commit('removeUser', userKey);

      return result;
    },
    async resetUserPassword({ commit }, userKey) {
      let url = `/api/admin/users/${userKey}/password_reset`;
      let result = await axios.get(url);

      return result;
    },
    async getManagers({ commit }, clientKey) {
      let url = `/api/admin/clients/${clientKey}/managers`;

      return axios.get(url);
    },
    async assignManagers({ commit }, { clientKey, managers }) {
      let url = `/api/admin/clients/${clientKey}/managers`;

      return axios.put(url, managers);
    },
    async updateUserAvatar({ commit }, { userKey, file }) {
      let url = `/api/admin/users/${userKey}/avatar`;

      // Read the file as array buffer
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);

      // Post the file
      return axios.put(url, file, {
        headers: {
          'Content-Type': 'text/plain'
        }
      });
    },
    async updateConfiguration({ commit }, configuration) {
      let url = `/api/admin/configuration`;
      let result = await axios.put(url, configuration);

      return result;
    },
    async updateLocale({ commit }, locale) {
      let url = `/api/admin/configuration/locales`;

      let result = await axios.put(url, locale);

      return result;
    },
    async updateFaqLibrary({ commit }, faqLibrary) {
      let url = `/api/admin/configuration/faq_library`;

      let result = await axios.put(url, faqLibrary);

      return result;
    }
  },
  getters: {
    clientsMap: state => {
      return state.clients.reduce((map, obj) => (map[obj._id] = obj, map), {});
    },
    clientsKeyMap: state => {
      return state.clients.reduce((map, obj) => (map[obj._key] = obj, map), {});
    },
    caseManagers: state => {
      return state.users.filter(u => u.roles.includes('caseManager'));
    }
  }
}

export default admin
