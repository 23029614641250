<template>
  <div style="height: 75vh;">
    <v-row>
      <v-col md=10 offset-md=1>
        <v-card
            class="pa-3"
            width="100%"
        >
          <v-card-title>
            Security Configuration
          </v-card-title>
          <v-card-text>
            <p>
              The list below is a list of file types that either be black- or whitelisted for uploading when
              a user submits new messages. Certain file types may impose a risk when being downloaded by a case manager,
              therefore it might make sense to forbid (blacklist) certain file types or whitelist allowed file types.
            </p>
            <p>
              As guessing the exact types of files that might be required when a whistleblower submits new data is
              very difficult, we provide an extensive blacklist that can be applied by default. This is the list loading
              automatically if nothing has been configured so far.
            </p>
            <v-radio-group
                v-model="listType"
                row
            >
              <v-radio
                  label="Blacklist"
                  value="blacklist"
              ></v-radio>
              <v-radio
                  label="Whitelist"
                  value="whitelist"
              ></v-radio>
            </v-radio-group>
            <v-combobox
                v-model="extensions"
                :filter="filter"
                :items="items"
                :hide-no-data="!search"
                :search-input.sync="search"
                hide-selected
                label="Add file extensions"
                multiple
                persistent-hint
                small-chips
                clearable
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :color="`${item.color} lighten-3`"
                    :input-value="selected"
                    label
                    small
                >
                  <span class="pr-2">
                    .{{ item.text }} ({{ item.description }})
                  </span>
                </v-chip>
              </template>
              <template v-slot:item="{ index, item }">
                  .{{ item.text }} ({{ item.description }})
              </template>
            </v-combobox>
          </v-card-text>
          <v-card-actions>
            <v-btn
                small
                color="primary"
                outlined
                @click="save"
            >
              Save
            </v-btn>
            <v-btn
                small
                color="primary"
                outlined
                @click="suggest_blacklist"
            >
              Default Blacklist
            </v-btn>
            <v-btn
                small
                color="primary"
                outlined
                @click="suggest_whitelist"
            >
              Default Whitelist
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "Security.vue",
  data: () => ({
    localConfiguration: {

    },
    listType: 'blacklist',
    blacklist: [
      {'text' : 'exe', 'description' : 'Microsoft Windows Executable'},
      {'text' : 'bat', 'description' : 'Microsoft Batch File'},
      {'text' : 'cmd', 'description' : 'Microsoft Batch File'},
      {'text' : 'com', 'description' : 'MS-Dos Executable'},
      {'text' : 'pif', 'description' : 'Short Cut to MS-Dos'},
      {'text' : 'scr', 'description' : 'Screen Saver'},
      {'text' : 'msi', 'description' : 'Microsoft Installer'},
      {'text' : 'msp', 'description' : 'Microsoft Installer Patch'},
      {'text' : 'jar', 'description' : 'Java Executable Code'},
      {'text' : 'vb', 'description' : 'VBScript'},
      {'text' : 'vbs', 'description' : 'VBScript'},
      {'text' : 'vbe', 'description' : 'Encrypted VBScript'},
      {'text' : 'js', 'description' : 'JavaScript'},
      {'text' : 'jse', 'description' : 'Encrypted JavaScript'},
      {'text' : 'ws', 'description' : 'Windows Script File'},
      {'text' : 'wsf', 'description' : 'Windows Script File'},
      {'text' : 'wsc', 'description' : 'Windows Script Component'},
      {'text' : 'wsh', 'description' : 'Windows Script Host'},

      {'text' : 'ps1', 'description' : 'Windows Powershell'},
      {'text' : 'ps1xml', 'description' : 'Windows Powershell'},
      {'text' : 'ps2', 'description' : 'Windows Powershell'},
      {'text' : 'ps2xml', 'description' : 'Windows Powershell'},
      {'text' : 'psc1', 'description' : 'Windows Powershell'},
      {'text' : 'psc2', 'description' : 'Windows Powershell'},

      {'text' : 'msh', 'description' : 'Monad Script'},
      {'text' : 'msh1', 'description' : 'Monad Script'},
      {'text' : 'msh2', 'description' : 'Monad Script'},
      {'text' : 'mshxml', 'description' : 'Monad Script'},
      {'text' : 'msh1xml', 'description' : 'Monad Script'},
      {'text' : 'msh2xml', 'description' : 'Monad Script'},

      {'text' : 'scf', 'description' : 'Windows Explorer Command'},
      {'text' : 'lnk', 'description' : 'Link File'},
      {'text' : 'inf', 'description' : 'AutoRun'},

      {'text' : 'chm', 'description' : 'Microsoft HTML File'},
      {'text' : 'drv', 'description' : 'Device Driver'},
      {'text' : 'vxd', 'description' : 'Virtual Device Driver'},
      {'text' : 'dll', 'description' : 'Dynamic Link Library'},
      {'text' : 'swf', 'description' : 'Shockwave Flash'},

      {'text' : 'ocx', 'description' : 'ActiveX Control'},
      {'text' : 'class', 'description' : 'Java Bytecode'},
    ],
    whitelist: [
      {'text' : 'doc', 'description' : 'Microsoft Office Document'},
      {'text' : 'docx', 'description' : 'Microsoft Office XML Document'},
      {'text' : 'ppt', 'description' : 'Microsoft Powerpoint Document'},
      {'text' : 'pptx', 'description' : 'Microsoft Powerpoint XML Document'},
      {'text' : 'xls', 'description' : 'Microsoft Excel Document'},
      {'text' : 'xlsx', 'description' : 'Microsoft Excel XML Document'},
      {'text' : 'pdf', 'description' : 'PDF Document'},
      {'text' : 'zip', 'description' : 'ZIP Archive'},
      {'text' : 'rar', 'description' : 'RAR Archive'},
      {'text' : 'tar', 'description' : 'TAR Archive'},
      {'text' : 'gz', 'description' : 'GZIP Compressed File'},
      {'text' : 'bz', 'description' : 'BZIP Compressed File'},
      {'text' : 'xv', 'description' : 'XV Compressed File'},
      {'text' : 'txt', 'description' : 'Text File'},
      {'text' : 'jpg', 'description' : 'JPEG Image'},
      {'text' : 'jpeg', 'description' : 'JPEG Image'},
      {'text' : 'png', 'description' : 'PNG Image'},
      {'text' : 'gif', 'description' : 'GIF Image'},
      {'text' : 'tiff', 'description' : 'TIFF Image'},
      {'text' : 'mp4', 'description' : 'MP4 Video'},
      {'text' : 'mov', 'description' : 'MOV Video'},
      {'text' : 'avi', 'description' : 'AVI Video'},
    ],
    items: [
      {'text' : 'exe', 'description' : 'Microsoft Windows Executable'},
      {'text' : 'bat', 'description' : 'Microsoft Batch File'},
      {'text' : 'cmd', 'description' : 'Microsoft Batch File'},
      {'text' : 'com', 'description' : 'MS-Dos Executable'},
      {'text' : 'pif', 'description' : 'Short Cut to MS-Dos'},
      {'text' : 'scr', 'description' : 'Screen Saver'},
      {'text' : 'msi', 'description' : 'Microsoft Installer'},
      {'text' : 'msp', 'description' : 'Microsoft Installer Patch'},
      {'text' : 'jar', 'description' : 'Java Executable Code'},
      {'text' : 'vb', 'description' : 'VBScript'},
      {'text' : 'vbs', 'description' : 'VBScript'},
      {'text' : 'vbe', 'description' : 'Encrypted VBScript'},
      {'text' : 'js', 'description' : 'JavaScript'},
      {'text' : 'jse', 'description' : 'Encrypted JavaScript'},
      {'text' : 'ws', 'description' : 'Windows Script File'},
      {'text' : 'wsf', 'description' : 'Windows Script File'},
      {'text' : 'wsc', 'description' : 'Windows Script Component'},
      {'text' : 'wsh', 'description' : 'Windows Script Host'},

      {'text' : 'ps1', 'description' : 'Windows Powershell'},
      {'text' : 'ps1xml', 'description' : 'Windows Powershell'},
      {'text' : 'ps2', 'description' : 'Windows Powershell'},
      {'text' : 'ps2xml', 'description' : 'Windows Powershell'},
      {'text' : 'psc1', 'description' : 'Windows Powershell'},
      {'text' : 'psc2', 'description' : 'Windows Powershell'},

      {'text' : 'msh', 'description' : 'Monad Script'},
      {'text' : 'msh1', 'description' : 'Monad Script'},
      {'text' : 'msh2', 'description' : 'Monad Script'},
      {'text' : 'mshxml', 'description' : 'Monad Script'},
      {'text' : 'msh1xml', 'description' : 'Monad Script'},
      {'text' : 'msh2xml', 'description' : 'Monad Script'},

      {'text' : 'scf', 'description' : 'Windows Explorer Command'},
      {'text' : 'lnk', 'description' : 'Link File'},
      {'text' : 'inf', 'description' : 'AutoRun'},

      {'text' : 'chm', 'description' : 'Microsoft HTML File'},
      {'text' : 'drv', 'description' : 'Device Driver'},
      {'text' : 'vxd', 'description' : 'Virtual Device Driver'},
      {'text' : 'dll', 'description' : 'Dynamic Link Library'},
      {'text' : 'swf', 'description' : 'Shockwave Flash'},

      {'text' : 'ocx', 'description' : 'ActiveX Control'},
      {'text' : 'class', 'description' : 'Java Bytecode'},
      {'text' : 'doc', 'description' : 'Microsoft Office Document'},
      {'text' : 'docx', 'description' : 'Microsoft Office XML Document'},
      {'text' : 'ppt', 'description' : 'Microsoft Powerpoint Document'},
      {'text' : 'pptx', 'description' : 'Microsoft Powerpoint XML Document'},
      {'text' : 'xls', 'description' : 'Microsoft Excel Document'},
      {'text' : 'xlsx', 'description' : 'Microsoft Excel XML Document'},
      {'text' : 'pdf', 'description' : 'PDF Document'},
      {'text' : 'zip', 'description' : 'ZIP Archive'},
      {'text' : 'rar', 'description' : 'RAR Archive'},
      {'text' : 'tar', 'description' : 'TAR Archive'},
      {'text' : 'gz', 'description' : 'GZIP Compressed File'},
      {'text' : 'bz', 'description' : 'BZIP Compressed File'},
      {'text' : 'xv', 'description' : 'XV Compressed File'},
      {'text' : 'txt', 'description' : 'Text File'},
      {'text' : 'jpg', 'description' : 'JPEG Image'},
      {'text' : 'jpeg', 'description' : 'JPEG Image'},
      {'text' : 'png', 'description' : 'PNG Image'},
      {'text' : 'gif', 'description' : 'GIF Image'},
      {'text' : 'tiff', 'description' : 'TIFF Image'},
      {'text' : 'mp4', 'description' : 'MP4 Video'},
      {'text' : 'mov', 'description' : 'MOV Video'},
      {'text' : 'avi', 'description' : 'AVI Video'},
    ],
    search: null,
    extensions: [],
  }),
  methods: {
    ...mapActions([
      'getConfiguration',
      'setLoading',
    ]),
    ...mapMutations([
      'setConfiguration',
    ]),
    ...mapActions('admin', [
      'getIdentity',
      'updateConfiguration',
    ]),
    filter(item, queryText, itemText) {
      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
    },
    async initData() {
      this.extensions = [];
      await this.getConfiguration();
      this.localConfiguration = JSON.parse(JSON.stringify(this.configuration));

      if(this.localConfiguration.fileTypeRestriction && this.localConfiguration.fileTypeRestriction.blackList) {
        this.extensions = [...this.localConfiguration.fileTypeRestriction.blackList];
        this.listType = 'blacklist';
      } else if (this.localConfiguration.fileTypeRestriction && this.localConfiguration.fileTypeRestriction.whiteList) {
        this.extensions = [...this.localConfiguration.fileTypeRestriction.whiteList];
        this.listType = 'whitelist';
      } else {
        this.listType = 'blacklist';
      }
    },
    async save() {
      await this.setLoading(true);

      try {
        const list = this.extensions.map(e => e.text);

        if(list.length === 0) {
          this.localConfiguration.fileTypeRestriction = null;
        } else {
          if (this.listType === 'blacklist') {
            this.localConfiguration.fileTypeRestriction = {
              blackList: list,
            }
          } else {
            this.localConfiguration.fileTypeRestriction = {
              whiteList: list,
            }
          }
        }
        await this.updateConfiguration(this.localConfiguration);
        this.setConfiguration(this.localConfiguration);
        this.$bus.$emit('successMessage', 'Configuration stored successfully');
      } catch(error) {
        this.$bus.$emit('errorMessage', 'Could not update configuration');
      } finally {
        this.editMode = false;
        await this.initData();
      }
    },
    suggest_blacklist() {
      this.extensions = [...this.blacklist];
      this.listType = 'blacklist';
    },
    suggest_whitelist() {
      this.extensions = [...this.whitelist];
      this.listType = 'whitelist';
    }
  },
  computed: {
    ...mapState([
      'configuration',
      'loading',
    ]),
  },
  watch: {
    extensions(val, prev) {
      if (val.length === prev.length) return;

      this.extensions = val.map(v => {
        if (typeof v === 'string') {
          const idx = this.items.findIndex(i => i.text === v);
          if(idx !== -1) {
            const item = this.items[idx];
            return item;
          } else
          {
            v = {
              text: v,
              description: 'Custom'
            };

            this.items.push(v);
          }
        }

        return v;
      })
    },
  },
  async mounted() {
    await this.initData();
  }
}
</script>

<style scoped>

</style>