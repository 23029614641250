import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Home from '@/components/Home.vue'
import Client from '@/components/Client.vue'
import NewCase from '@/components/NewCase.vue'
import Case from '@/components/Case.vue'

import AdminView from '@/views/AdminView.vue'
import Dashboard from '@/components/admin/Dashboard.vue'
import Users from '@/components/admin/Users.vue'
import Clients from '@/components/admin/Clients.vue'
import Cases from '@/components/admin/Cases.vue'
import CaseDetails from '@/components/admin/CaseDetails.vue'
import Login from '@/components/admin/Login.vue'

import ConfigurationView from '@/views/ConfigurationView.vue'
import GlobalConfiguration from '@/components/admin/configuration/GlobalConfiguration.vue'
import LanguageConfiguration from '@/components/admin/configuration/LanguageConfiguration.vue'
import LayoutConfiguration from '@/components/admin/configuration/LayoutConfiguration.vue'
import FaqConfiguration from '@/components/admin/configuration/FaqConfiguration.vue'
import SecurityConfiguration from '@/components/admin/configuration/SecurityConfiguration.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/admin',
    component: AdminView,
    children: [
      {
        path: '',
        component: Dashboard
      },
      {
        path: 'login',
        component: Login
      },
      {
        path: 'cases',
        component: Cases
      },
      {
        path: 'cases/:key',
        component: CaseDetails,
      },
      {
        path: 'users',
        component: Users
      },
      {
        path: 'clients',
        component: Clients
      },
      {
        path: 'configuration',
        component: ConfigurationView,
        children: [
          {
            path: '',
            component: GlobalConfiguration,
          },
          {
            path: 'security',
            component: SecurityConfiguration,
          },
          {
            path: 'languages',
            component: LanguageConfiguration,
          },
          {
            path: 'layouts',
            component: LayoutConfiguration,
          },
          {
            path: 'faq',
            component: FaqConfiguration,
          }
        ]
      }
    ],
  },
  {
    path: '/:clientId',
    name: 'client',
    component: Client,
  },
  {
    path: '/:clientId/newcase/:caseType',
    name: 'newcase',
    component: NewCase,
  },
  {
    path: '/:clientId/:caseKey',
    name: 'case',
    component: Case,
    beforeLeave: (to, from, next) => {
      store.dispatch('destroyAuthData');
      next();
    }
  },
  {
    path: '*',
    name: 'fallBack',
    component: Home,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
