<template>
  <div style="height: 75vh;">
    <v-row>
      <v-col md=10 offset-md=1>
        <v-card class="pa-3">
          <v-card-title>
            Global Configuration
          </v-card-title>
          <v-card-text>
            <p>Global configuration values apply to all clients; therefore changes applied will be visible across all clients.</p>
            <v-col cols=2>
              <v-skeleton-loader v-show="loading" type="text"></v-skeleton-loader>
              <v-text-field
                v-show="!loading"
                v-model="localConfiguration.operatorName"
                label="Operator Name"
                :disabled="!editMode"
              >
              </v-text-field>
            </v-col>
            <v-col cols=2>
              <v-skeleton-loader v-show="loading" type="text"></v-skeleton-loader>
              <v-select
                v-show="!loading"
                v-model="localConfiguration.defaultLanguage"
                :items="localConfiguration.languages"
                item-text="name"
                label="Default Language"
                :disabled="!editMode"
                return-object
              >
              </v-select>
            </v-col>
            <v-data-table
              :headers="headers"
              :items="localConfiguration.languages"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Languages</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="addLanguagesDialog" width="unset" max-width="500px">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        small
                        outlined
                        class="mb-2"
                        v-on="on"
                        :disabled="!editMode"
                      >
                        Add Language
                      </v-btn>
                    </template>
                    <v-card class="pa-3">
                      <v-card-title>
                        <span class="headline">Add Language</span>
                      </v-card-title>

                      <v-card-text>
                        <v-select
                          v-model="languagesToAdd"
                          :items="unsetLanguages"
                          item-text="name"
                          label="Select"
                          dense
                          multiple
                          chips
                          hint="Select languages to add"
                          persistent-hint
                          small-chips
                          deletable-chips
                          return-object
                        >
                        </v-select>
   
                      </v-card-text>

                      <v-card-actions>
                        <v-btn
                          color="primary"
                          small
                          outlined
                          @click="addLanguages"
                        >
                          Add
                        </v-btn>
                        <v-btn
                          color="primary"
                          small
                          outlined
                          @click="cancelAddLanguages"
                        >
                          Cancel
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:body.prepend = "{ }">
                <tr v-show="loading" v-for="i of 1,2,3,4">
                  <td v-for="i of 1,2,3,4">
                    <v-skeleton-loader type="table-cell"></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-slot:item="{ item, index }">
                <tr v-show="!loading">
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    <v-checkbox
                      dense
                      v-model=item.active
                      :disabled="!editMode"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          color="red"
                          class="mr-2"
                          @click="deleteLanguage(item)"
                          v-on="on"
                          :disabled="!editMode"
                        >
                          delete
                        </v-icon>
                      </template>
                      <span>Delete Language</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              color="primary"
              outlined
              @click="editMode? save() : edit()"
            >
              {{ editMode ? 'Save' : 'Edit' }}
            </v-btn>
            <v-btn
              small
              color="primary"
              outlined
              :disabled="!editMode"
              @click="cancel()"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

export default {
  data: () => ({
    editMode: false,
    localConfiguration: { languages: [] },

    addLanguagesDialog: false,
    languagesToAdd: [],
    allLanguages: [
      {
        name: 'German',
        code: 'de',
        active: false,
      },
      {
        name: 'English',
        code: 'en',
        active: false,
      },
      {
        name: 'French',
        code: 'fr',
        active: false,
      },
      {
        name: 'Italian',
        code: 'it',
        active: false,
      },
    ],

    headers: [
      {
        text: 'Language Name',
        align: 'left',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Language Code',
        align: 'left',
        sortable: true,
        value: 'code',
      },
      {
        text: 'Active',
        align: 'left',
        sortable: true,
        value: 'active',
      },
      {
        text: 'Actions',
        align: 'left',
        sortable: false,
        value: 'action',
      },
    ],
  }),
  computed: {
    ...mapState([
      'configuration',
      'loading',
    ]),
    unsetLanguages() {
      if(!this.localConfiguration.languages || this.localConfiguration.languages.length === 0)
        return [];

      let used = this.localConfiguration.languages.map(x => x.code);
      return this.allLanguages.filter(x => !used.includes(x.code));
    }
  },
  methods: {
    ...mapActions([
      'getConfiguration',
      'setLoading',
    ]),
    ...mapMutations([
      'setConfiguration',
    ]),
    ...mapActions('admin', [
      'getIdentity',
      'updateConfiguration',
    ]),
    async initData() {
      try {
        await this.getConfiguration();
        this.localConfiguration = JSON.parse(JSON.stringify(this.configuration));
      } catch(error) {
      } finally {
        await this.setLoading(false);
      }
    },
    edit() {
      this.editMode = true;
    },
    verifyLocalConfiguration() {
      let defaultLocale = this.localConfiguration.defaultLanguage.code;

      let defaultLanguage = this.localConfiguration.languages.find(x => x.code == defaultLocale);

      if(!defaultLanguage || !defaultLanguage.active) {
        this.$bus.$emit('errorMessage', 'Default language must be active');
        return false;
      }

      return true;
    },
    async save() {
      // Verify the configuration
      if(this.verifyLocalConfiguration() === true) {
        this.setLoading(true);

        try {
          await this.updateConfiguration(this.localConfiguration);
          this.setConfiguration(this.localConfiguration);
          this.$bus.$emit('successMessage', 'Configuration stored successfully');
        } catch(error) {
          this.$bus.$emit('errorMessage', 'Could not update configuration');
        } finally {
          this.editMode = false;
          this.initData();
        }
      }
    },
    deleteLanguage(language) {
      let idx = this.localConfiguration.languages.findIndex(x => x.code === language.code);

      if(idx !== -1)
        this.localConfiguration.languages.splice(idx, 1);
    },
    cancel() {
      this.editMode = false;
      this.initData();
    },
    addLanguages() {
      this.languagesToAdd.forEach(x => this.localConfiguration.languages.push(x));
      this.languagesToAdd = [];
      this.addLanguagesDialog = false;
    },
    cancelAddLanguages() {
      this.languagesToAdd = [];
      this.addLanguagesDialog = false;
    }
  },
  async mounted() {
    await this.setLoading(true);
    // As we do not fetch any attributes that require authentication, we
    // fetch the identity here. That way a 'timed out' session will be
    // forwarded to the login screen. Otherwise users might invest a lot
    // of work into editing but they could never store it.
    await this.getIdentity();

    await this.initData();
  }
}

</script>

<style scoped>

</style>