<template>
  <div>
    <v-card class="pa-3">
      <v-card-title>
        <v-col cols="9" class="px-0">
        {{ $t('titleCaseDetails') }}
        </v-col>
        <v-col v-if="availableLocales.length > 1" cols="3" align="right">
          <v-icon
            :small="$vuetify.breakpoint.xsOnly"
            class="ml-4 mr-1"
          >
            language
          </v-icon>
          <v-select
            class="langselect d-inline-flex"
            dense
            single-line
            v-model="locale"
            :items="availableLocales"
          >
          </v-select>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-row v-if="layout !== null">
          <template v-for="(col, index) in layout.columns" >
            <v-col cols="12" :md="getFieldWidth(col)">
              <p class="body-2">
                {{ getFieldHint(col) }}
              </p>
              <component
                v-model="currentCase.metaData[layout.fields[col].name]"
                :is="layout.fields[col].component"
                :outlined="layout.fields[col].outlined"
                :no-resize="true"
                :rows="getFieldRows(col)"
                :items="layout.fields[col].choices"
                item-value="en"
                item-text="en"
                readonly
                class="disabled"
              >
                <v-radio
                  v-if="layout.fields[col].component === 'v-radio-group'"
                  v-for="(choice, index) in layout.fields[col].choices"
                  :key="index"
                  :label="choice[currentLocale]"
                  :value="index"
                  disabled
                >
                </v-radio>
                <v-checkbox
                  disabled
                  v-if="layout.fields[col].type === 'multipleChoice'"
                  v-model="currentCase.metaData[layout.fields[col].name]"
                  v-for="(choice, index) in layout.fields[col].choices"
                  class="ma-0 pa-0"
                  multiple
                  :hide-details="index !== (layout.fields[col].choices.length -1)"
                  :key="index"
                  :label="choice[currentLocale]"
                  :value="index"
                >
                </v-checkbox>
              </component>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-btn
            small
            color="primary"
            depressed
            @click="close"
          >
            {{ $t('labelClose') }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { VDatePicker, VTextField, VTextarea, VSwitch, VRadioGroup, VRadio, VItemGroup, VSelect } from 'vuetify/lib'
import { Layout, Field } from '@/assets/layout'

export default {
  components: {
      VTextField,
      VTextarea,
      VSwitch,
      VDatePicker,
      VRadioGroup,
      VRadio,
      VItemGroup,
      VSelect,
  },
  data: () => ({
    locale: 'en',
  }),
  props: [ 'currentCase' ],
  methods: {
    getFieldWidth(fieldIndex) {
      let field = this.layout.fields[fieldIndex];

      if(field === null || typeof(field) === 'undefined')
        return 6;

      if(field.fullWidth === true)
        return 12;

      return field.width;
    },
    getFieldLabel(fieldIndex) {
      let field = this.layout.fields[fieldIndex];

      if(field === null || typeof(field) === 'undefined')
        return "";

      let locale = this.$i18n.locale;

      if(field.required === true)
        return `${field.displayNames[locale]}*`;
      else
        return field.displayNames[locale];
    },
    getFieldHint(fieldIndex) {
      let field = this.layout.fields[fieldIndex];

      if(field === null || typeof(field) === 'undefined')
        return "";

      return field.hints[this.locale];
    },
    getFieldRows(fieldIndex) {
      let field = this.layout.fields[fieldIndex];

      if(field === null || typeof(field) === 'undefined')
        return 0;

      return field.rows;
    },
    close() {
      this.$emit('close');
    }
  },
  computed: {
    layout() {
      return Layout.fromObject(this.currentCase.layout);
    },
    availableLocales() {
      return Object.keys(this.currentCase.layout.fields[0].displayNames);
    },
    currentLocale() {
      let locales = this.availableLocales;

      if(locales.includes(this.locale)) {
        return this.locale;
      }

      let loc = this.$i18n.locale;

      if(locales.includes(loc)) {
        return loc;
      }

      return locales[0];
    }
  },
  mounted() {
    let locales = this.availableLocales;

    if(locales.includes(this.locale) === false)
      this.locale = locales[0];
  }
}

</script>

<style scoped lang="scss">
::v-deep .body-2 {
  font-family: 'Rubik', sans-serif !important;
}

::v-deep .disabled textarea {
  pointer-event: none !important;
  color: rgba(0, 0, 0, 0.4) !important;

}

::v-deep fieldset {
  border: none !important;
  background-color: #E3EEF0;
}

::v-deep .v-label {
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.4) !important;
}
</style>