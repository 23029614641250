<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-row justify="center" style="padding: 50px 00px;">
              <v-card class="pb-3" width="30%" flat tile>
                <v-toolbar flat style="border-bottom: 1px solid #eeeeee">
                  <v-toolbar-title class="primary--text">Log In</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-form ref="loginForm" onsubmit="return false;">
                    <v-row align="center">
                      <v-col cols="8" offset-md="2">
                        <v-text-field
                          v-model="credentials.emailAddress"
                          label="Email Address"
                          hint="Please enter your email address"
                          validate-on-blur
                          :rules="emailRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col cols="8" offset-md="2">
                        <v-text-field
                          v-model="credentials.password"
                          label="Password"
                          hint="Please enter your password"
                          type="password"
                          validate-on-blur
                          :rules="passwordRules"
                          @keyup.native.enter.stop="getOtp"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn small outlined class="ml-2" color="primary" @click="getOtp">Continue</v-btn>
                </v-card-actions>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-dialog v-model="pwChangeDialog" persistent width="unset">
      <v-card class="pb-3" width="400px">
        <v-toolbar class dark color="primary">
          <v-toolbar-title>Password change</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form ref="pwChangeForm" onsubmit="return false;">
            <v-row align="center">
              <v-col cols="8" offset-md="2">
                <v-text-field
                  v-model="credentials.password"
                  label="Current Password"
                  hint="Please enter your current password"
                  type="password"
                  validate-on-blur
                  :rules="passwordRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="8" offset-md="2">
                <v-text-field
                  v-model="credentials.newPassword"
                  label="New Password"
                  hint="Please enter your new password"
                  type="password"
                  validate-on-blur
                  :rules="passwordRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="8" offset-md="2">
                <v-text-field
                  v-model="credentials.confirmPassword"
                  label="Confirm Password"
                  hint="Please enter your new password again"
                  type="password"
                  validate-on-blur
                  :rules="passwordRules"
                  @keyup.native.enter.stop="getPwChangeOtp"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn small outlined class="ml-2" color="primary" @click="getPwChangeOtp">Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="otpDialog" persistent width="unset">
      <v-card class="pa-3">
        <v-card-text>
          <img src="/img/yubikey.png" width="175px" />
          <v-form ref="otpForm" onsubmit="return false;">
            <v-text-field
              v-model="credentials.otp"
              ref="otpField"
              autofocus
              label="One Time Password"
              validate-on-blur
              :rules="otpRules"
              @keyup.native.enter.stop="login"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" small outlined :disabled="loading" @click="login">Login</v-btn>
          <v-btn
            color="primary"
            small
            outlined
            :disabled="loading"
            @click="otpDialog = !otpDialog"
          >Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "Setup",

  data: () => ({
    credentials: {
      emailAddress: "",
      password: "",
      newPassword: null,
      confirmPassword: null,
      otp: "",
    },
    otpDialog: false,
    pwChangeDialog: false,
  }),
  methods: {
    ...mapMutations(["setLoading"]),
    ...mapActions("admin", ["changePassword", "sendCredentials"]),
    ...mapMutations("admin", ["setAuthenticated", "setToken"]),
    getOtp() {
      if (this.$refs.loginForm.validate() === true) {
        this.otpDialog = true;
      }
    },
    getPwChangeOtp() {
      if (this.$refs.pwChangeForm.validate() === true) {
        this.otpDialog = true;
      }
    },
    login() {
      if (this.$refs.otpForm.validate() === true) {
        this.setLoading(true);

        if (this.pwChangeDialog === true) {
          this.changePassword(this.credentials)
            .then((response) => {
              this.setLoading(false);
              this.otpDialog = false;
              this.pwChangeDialog = false;
              let token = response.data.data.token;
              this.setToken(token);
              this.setAuthenticated(true);

              if(this.self.admin)
                this.$router.push("/admin/clients");
              else if(this.self.contentManager)
                this.$router.push("/admin/configuration");
              else
                this.$router.push("/admin/cases");
            })
            .catch((error) => {
              this.setLoading(false);
              this.otpDialog = false;
              this.credentials.otp = "";

              this.$bus.$emit('errorMessage', error.response.data.errors[0].message);
            });
        } else {
          this.sendCredentials(this.credentials)
            .then((response) => {
              this.setLoading(false);
              let msg = response.data.message;
              this.otpDialog = false;
              this.credentials.otp = "";
              this.credentials.password = "";

              if (msg === "Password change required") {
                this.pwChangeDialog = true;
              } else {
                let token = response.data.data.token;
                this.setToken(token);
                this.setAuthenticated(true);

                if(this.self.admin)
                  this.$router.push("/admin/clients");
                else if(this.self.contentManager)
                  this.$router.push("/admin/configuration");
                else
                  this.$router.push("/admin/cases");
              }
            })
            .catch((error) => {
              this.setLoading(false);
              this.otpDialog = false;
              this.credentials.otp = "";
              this.credentials.password = "";
              this.credentials.emailAddress = "";

              this.$bus.$emit('errorMessage', error.response.data.errors[0].message);
            });
        }
      }
    },
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("admin", ["authenticated", "self"]),
    emailRules() {
      return [
        (v) => !!v || "Email address is required!",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Email address must be valid!",
      ];
    },
    passwordRules() {
      return [(v) => !!v || "Password is required"];
    },
    otpRules() {
      return [
        (v) => !!v || "OTP is required",
        (v) => v.length === 44 || "OTP format is wrong",
      ];
    },
  },
  async mounted() {

  },
};
</script>

<style>
</style>