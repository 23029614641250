<template>
  <div class="home">
    <v-main align="center" class="fill-height mt-0 pt-5" row wrap style="width: 100%">
      <v-row v-if="!loading" justify="start" align="center">
        <v-col
          cols="10"
          offset="1"
          sm="8"
          offset-sm="2"
          md="6"
          offset-md="3"
          lg="6"
          offset-lg="3"
          class="pa-0"
        >
          <v-card class="pa-0" flat tile color="primary" width="100%">
            <span class="white--text">
              <v-card-title
                class="justify-center canary-title"
                v-html="$t('titleWelcome', { operatorName: operatorName })"
              >></v-card-title>
            </span>
            <v-card-text
              class="white--text text-left text-default"
              v-html="$t('messageWelcome', { operatorName: operatorName })"
            ></v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10" sm="8" md="8" lg="6" class="pa-0">
          <v-card width="100%" class="px-3 py-0" flat tile>
            <v-card-text v-html="$t('messageWelcomeAddendum')"></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapGetters(["operatorName"]),
    ...mapState(["loading"]),
  },
};
</script>

<style scoped>
.home {
  height: 80vh;
}
</style>