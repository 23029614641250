var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"75vh"}},[_c('v-row',[_c('v-col',{attrs:{"md":"10","offset-md":"1"}},[_c('v-card',{staticClass:"pa-3",attrs:{"width":"100%"}},[_c('v-card-title',[_vm._v(" Security Configuration ")]),_c('v-card-text',[_c('p',[_vm._v(" The list below is a list of file types that either be black- or whitelisted for uploading when a user submits new messages. Certain file types may impose a risk when being downloaded by a case manager, therefore it might make sense to forbid (blacklist) certain file types or whitelist allowed file types. ")]),_c('p',[_vm._v(" As guessing the exact types of files that might be required when a whistleblower submits new data is very difficult, we provide an extensive blacklist that can be applied by default. This is the list loading automatically if nothing has been configured so far. ")]),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.listType),callback:function ($$v) {_vm.listType=$$v},expression:"listType"}},[_c('v-radio',{attrs:{"label":"Blacklist","value":"blacklist"}}),_c('v-radio',{attrs:{"label":"Whitelist","value":"whitelist"}})],1),_c('v-combobox',{attrs:{"filter":_vm.filter,"items":_vm.items,"hide-no-data":!_vm.search,"search-input":_vm.search,"hide-selected":"","label":"Add file extensions","multiple":"","persistent-hint":"","small-chips":"","clearable":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" No results matching \""),_c('strong',[_vm._v(_vm._s(_vm.search))]),_vm._v("\". Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to create a new one ")])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":((item.color) + " lighten-3"),"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" ."+_vm._s(item.text)+" ("+_vm._s(item.description)+") ")])]):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_vm._v(" ."+_vm._s(item.text)+" ("+_vm._s(item.description)+") ")]}}]),model:{value:(_vm.extensions),callback:function ($$v) {_vm.extensions=$$v},expression:"extensions"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","color":"primary","outlined":""},on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('v-btn',{attrs:{"small":"","color":"primary","outlined":""},on:{"click":_vm.suggest_blacklist}},[_vm._v(" Default Blacklist ")]),_c('v-btn',{attrs:{"small":"","color":"primary","outlined":""},on:{"click":_vm.suggest_whitelist}},[_vm._v(" Default Whitelist ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }